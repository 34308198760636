import instagram from "../assets/footer/instagram.png";
import twitter from "../assets/footer/twitter.png";
import linkedin from "../assets/footer/linkedin.png";

import coloredLogo from "../assets/Niyo-Bootcamps-Logo-1.svg";
import whiteLogo from "../assets/Niyo-Bootcamps-Logo-2.svg";
import hero from "../assets/hero-background.png";
import hamburgerImage from "../assets/header/hamburger.png";
import joinBackground from "../assets/header/join-background.png";

import hero1 from "../assets/home/hero/home-hero-1.png";
import hero2 from "../assets/home/hero/home-hero-2.png";
import hero3 from "../assets/home/hero/home-hero-3.png";
import hero4 from "../assets/home/hero/home-hero-4.png";
import whiteArrow from "../assets/home/mission/white-arrow.png";
import blackArrow from "../assets/home/grads/black-arrow.png";
import gradArrow from "../assets/home/bootcamps/arrows/grad-arrow.png";
import partnerPlaceholder from "../assets/home/partner/partner-placeholder.png";
import homeBackground1 from "../assets/home/background/home-background-1.png";
import homeBackground2 from "../assets/home/background/home-background-2.png";

import slack from "../assets/header/slack.png";

import apple from "../assets/home/partner/apple.png";
import kpmg from "../assets/home/partner/kpmg.png";
import northrop from "../assets/home/partner/northrop.png";
import goldman from "../assets/home/partner/goldman.png";
import microsoft from "../assets/home/partner/microsoft.png";
import gchq from "../assets/home/partner/gchq.png";
import meta from "../assets/home/partner/meta.png";
import bbc from "../assets/home/partner/bbc.png";
import correla from "../assets/home/partner/correla.png";
import google from "../assets/home/partner/google.png";
import cabinet from "../assets/home/partner/cabinet.png";
import dfe from "../assets/home/partner/dfe.png";
import zappi from "../assets/home/partner/zappi.png";
import substrakt from "../assets/home/partner/substrakt.png";
import wmca from "../assets/home/partner/wmca.png";
import accenture from "../assets/home/partner/accenture.png";
import amazon from "../assets/home/partner/amazon.png";

import supportLogo1 from "../assets/home/support/support-logo-1.png";
import supportLogo2 from "../assets/home/support/support-logo-2.png";
import supportLogo3 from "../assets/home/support/support-logo-3.png";
import supportLogo4 from "../assets/home/support/support-logo-4.png";
import supportLogo5 from "../assets/home/support/support-logo-5.png";
import supportLogo6 from "../assets/home/support/support-logo-6.png";
import supportLogo7 from "../assets/home/support/support-logo-7.png";
import supportLogo8 from "../assets/home/support/support-logo-8.png";
import supportLogo9 from "../assets/home/support/support-logo-9.png";
import supportLogo10 from "../assets/home/support/support-logo-10.png";

import upskillLogo1 from "../assets/ourStory/upskill/upskill-logo-1.png";
import upskillLogo2 from "../assets/ourStory/upskill/upskill-logo-2.png";
import upskillLogo3 from "../assets/ourStory/upskill/upskill-logo-3.png";
import upskillLogo4 from "../assets/ourStory/upskill/upskill-logo-4.png";

import techBootcamp from "../assets/home/bootcamps/tech-bootcamp.png";
import hairBootcamp from "../assets/home/bootcamps/hair-bootcamp.png";
import foundherBootcamp from "../assets/home/bootcamps/foundher-bootcamp.png";
import fashionBootcamp from "../assets/home/bootcamps/fashion-bootcamp.png";
import web3Bootcamp from "../assets/home/bootcamps/web3-bootcamp.png";
import yellowArrow from "../assets/home/bootcamps/arrows/yellow-arrow.png";
import whiteArrow2 from "../assets/home/bootcamps/arrows/white-arrow.png";
import pinkArrow from "../assets/home/bootcamps/arrows/pink-arrow.png";
import ochreArrow from "../assets/home/bootcamps/arrows/ochre-arrow.png";
import orangeArrow from "../assets/home/bootcamps/arrows/orange-arrow.png";

import storyHero from "../assets/ourStory/hero/story-hero.png";
import aboutImage1 from "../assets/ourStory/about/about-image-1.png";
import aboutImage2 from "../assets/ourStory/about/about-image-2.png";

import impactLogo1 from "../assets/ourStory/impact/impact-logo-1.png";

import oyin from "../assets/ourStory/team/oyin.jpg";
import laolu from "../assets/ourStory/team/laolu.jpg";
import dami from "../assets/ourStory/team/dami.png";
import chinazor from "../assets/ourStory/team/chinazor.jpg";
import victoria from "../assets/ourStory/team/victoria.jpg";
import sephora from "../assets/ourStory/team/sephora.jpg";
import hannah from "../assets/ourStory/team/hannah.jpg";

import voice from "../assets/ourStory/press/voice.png";

import workLogo1 from "../assets/partners/workBoxes/work-logo-1.png";
import workLogo2 from "../assets/partners/workBoxes/work-logo-2.png";
import workLogo3 from "../assets/partners/workBoxes/work-logo-3.png";
import workLogo4 from "../assets/partners/workBoxes/work-logo-4.png";
import workLogo5 from "../assets/partners/workBoxes/work-logo-5.png";

import partnerHero from "../assets/partners/backgrounds/partner-hero.png";
import web2 from "../assets/partners/offerings/web2.png";
import web3 from "../assets/partners/offerings/web3.png";
import bootcampHero from "../assets/bootcamps/hero/bootcamp-hero.png";
import rating from "../assets/bootcamps/bootcampCards/rating.png";
import web2One from "../assets/bootcamps/web2/fullstack.png";
import web2Two from "../assets/bootcamps/web2/data.png";
import web3One from "../assets/bootcamps/web3/metaverse.png";
import web3Two from "../assets/bootcamps/web3/build-first.png";
import web3Three from "../assets/bootcamps/web3/build-second.png";
import hairOne from "../assets/bootcamps/hair/hair-hero.png";
import hairPrice1 from "../assets/bootcamps/hair/hair-hero.png";
import foundhersOne from "../assets/bootcamps/foundhers/foundhers-hero.png";
// import foundhersTwo from "../assets/bootcamps/bootcampCards/foundhers-2.png";
import web2Hero from "../assets/bootcamps/web2/web2-hero.png";
import web3Hero from "../assets/bootcamps/web3/web3-hero.png";
import hairHero from "../assets/bootcamps/hair/hero.png";
import foundhersHero from "../assets/bootcamps/foundhers/hero.png";
import fashionHero from "../assets/bootcamps/fashion/fashion-hero.png";
import testing from "../assets/bootcamps/web2/testing.png";

import soon from "../assets/bootcamps/fashion/soon.png";

//Coming soon web2 bootcamps
import cyber from "../assets/bootcamps/web2/cyber.png";
import product from "../assets/bootcamps/web2/product.png";
import digital from "../assets/bootcamps/web2/digital.png";
import robotics from "../assets/bootcamps/web2/robotics.png";
import architecture from "../assets/bootcamps/web2/tech-archi.png";
import UI from "../assets/bootcamps/web2/user-interface.png";
import intel from "../assets/bootcamps/web2/artificial-intel.png";
import software from "../assets/bootcamps/web2/software.png";

import nft from "../assets/bootcamps/web3/nft.png";
import design from "../assets/bootcamps/web3/design.png";
import dao from "../assets/bootcamps/web3/dao.png";
import web4 from "../assets/bootcamps/web3/build-third.png";

import number1Dark from "../assets/registration/number-1-dark.png";
import number2Dark from "../assets/registration/number-2-dark.png";
import number3Dark from "../assets/registration/number-3-dark.png";

import uploadButton1 from "../assets/registration/upload-button-1.png";
import uploadButton2 from "../assets/registration/upload-button-2.png";

import communityArrow from "../assets/community/community-arrow.png";
import slackImage from "../assets/community/slack-image.png";
import jobsImage from "../assets/community/jobs-image.png";
import eventsImage from "../assets/community/events-image.png";
import blogsImage from "../assets/community/blogs-image.png";
import stripe from "../assets/jobs/payment/stripe.png";
import goCardless from "../assets/jobs/payment/go-cardless.png";

import bootcampVideo from "../assets/individualBootcamps/bootcamp-video.png";
import week1 from "../assets/individualBootcamps/week-1.png";
import syllabusIcon from "../assets/individualBootcamps/syllabus-icon.png";
import foundhersBackground from "../assets/individualBootcamps/hero-images/foundhers-background.png";
import softwareBackground from "../assets/individualBootcamps/hero-images/software-background.png";
import dataBackground from "../assets/individualBootcamps/hero-images/data-background.png";
import hairBackground from "../assets/individualBootcamps/hero-images/hair-background.png";

import tutorOyin from "../assets/individualBootcamps/tutors/tutor-oyin.png";
import tutorLaolu from "../assets/individualBootcamps/tutors/tutor-laolu.png";
import tutorDaniella from "../assets/individualBootcamps/tutors/tutor-daniella.png";
import tutorPeace from "../assets/individualBootcamps/tutors/tutor-peace.png";
import tutorAmon from "../assets/individualBootcamps/tutors/tutor-amon.png";
import tutorTru from "../assets/individualBootcamps/tutors/tutor-tru.png";

import jobsHero from "../assets/jobs/jobs-hero.png";
import jobsLogo from "../assets/jobs/jobs-logo.png";
import jobTag from "../assets/jobs/job-tag.png";

import progressHalf from "../assets/jobs/progress-half.png";
import progressFull from "../assets/jobs/progress-full.png";
import wallet from "../assets/jobs/wallet.png";

import bigbreak from "../assets/break.png"

///////// FOOTER

const ourSocials = [
  {
    image: instagram,
    url: "https://instagram.com/niyobootcamps?igshid=YmMyMTA2M2Y=",
  },
  {
    image: twitter,
    url: "https://twitter.com/niyobootcamps?s=21&t=pGaqXPG0rgChO94aYF2r7g",
  },
  { image: linkedin, url: "https://www.linkedin.com/school/niyobootcamps/" },
];

///////// HOME

const Logos = {
  coloredLogo,
  whiteLogo,
  hamburgerImage,
  joinBackground,
};

const Background = {
  hero,
};

const HomeImages = {
  hero1,
  hero2,
  hero3,
  hero4,
  whiteArrow,
  blackArrow,
  gradArrow,
  partnerPlaceholder,
  homeBackground1,
  homeBackground2,
};

const PartnerBrands1 = [
  { image: apple },
  { image: kpmg },
  { image: northrop },
  { image: goldman },
  { image: microsoft },
  { image: gchq },

  { image: meta },
  { image: bbc },
  { image: correla },
  { image: google },
];

const PartnerBrands2 = [
  { image: cabinet },

  { image: dfe },
  { image: zappi },
  { image: substrakt },
  { image: wmca },
  { image: accenture },
  { image: amazon },
];

const bespokeSupport = [
  {
    logo: supportLogo1,
    title: "Scholarships",
    text: "This means that you will never have to worry about paying a penny to learn a new skill. Our partners have ensured that you are able to upskill at the comfort of your home with no cost to you.",
  },
  {
    logo: supportLogo2,
    title: "Free Counselling Sessions",
    text: "Every student has access to a number of wellbeing and counselling sessions with our partners at frontline therapists.",
  },
  {
    logo: supportLogo3,
    title: "Childcare Support",
    text: "Every mum on our bootcamps is eligible to apply to receive up to £250 in childcare vouchers as a way of supporting you whilst learning.",
  },
  {
    logo: supportLogo4,
    title: "4.75 / 5 Average Reviews ",
    hyperLink: "https://www.switchup.org/bootcamps/niyo-bootcamps",
    text: "We are focused on ensuring every student has the best experience our bootcamps have to offer.",
  },
  {
    logo: supportLogo5,
    title: "75% Employment Rate",
    text: "This is from our 2020 outcomes. Our students have been able to go on to work at some awesome organisations from junior to senior levels.",
  },
  {
    logo: supportLogo6,
    title: "560 people Upskilled",
    text: "Since 2020, we have upskilled 560  to start a career in tech and progress into more senior roles in tech in companies like Northrop Grumman, Zappi, KPMG and many more.",
  },
  {
    logo: supportLogo3,
    title: "Dedicated Career Support from Day One",
    text: "You will have Hannah, our employability and career coach here to support you from your first day. She will help you with your LinkedIn, CV and also interview coaching.",
  },
  {
    logo: supportLogo7,
    title: "Exclusive Access to Open Roles to All Our Hiring Partners ",
    text: "Exclusive access to open roles to all our hiring partners.",
  },
  {
    logo: supportLogo8,
    title: "Cool Rewards",
    text: "We like to reward our students during really important milestones. All our rewards come with an element of surprise.",
  },
  {
    logo: supportLogo9,
    title: "Empowerment Evenings and Events",
    text: "Free access to empowerment workshops and events by men and women who are excelling in their careers and businesses.",
  },
  {
    logo: supportLogo10,
    title: "7000+ Community of talents ",
    text: "We’ve grown an organic community of 7000 people who are passionate about tech and the intersections of it with other industries.",
  },
];

const ourBootcamps = [
  {
    image: techBootcamp,
    textBackground: "#fff",
    color: "#07909",
    name: "Technology Bootcamps",
    description:
      "This is a part-time on-demand, accelerated web development bootcamp for students to learn web2 technologies.",
    button: "#E09D00",
    buttonLink: "/web2-bootcamps",
    arrow: yellowArrow,
  },
  {
    image: web3Bootcamp,
    textBackground: "#070909",
    color: "#fff",
    name: "Web 3 Technology Bootcamps",
    description:
      "In this bootcamp, you’ll learn how to use Web3 technologies (like NFTs and smart contracts) on the different blockchains for creating decentralized applications.",

    button: "#fff",
    buttonLink: "/web3-bootcamps",
    arrow: whiteArrow2,
  },
  {
    image: hairBootcamp,
    textBackground: "#fff",
    color: "#07909",
    name: "Hair and Beauty Technology Bootcamps",
    description:
      "This  is a program designed to help Hairdressers gain the knowledge and skills needed to compete in the marketplace with quality training, educational courses and certification.",

    button: "#FF1FC3",
    buttonLink: "/hair-bootcamps",
    arrow: pinkArrow,
  },
  {
    image: foundherBootcamp,
    textBackground: "#fff",
    color: "#07909",
    name: "Foundhers Bootcamp",
    description:
      "This bootcamp is a 16-week business accelerator designed to provide FoundHers access to mentorship, finance, and high-value content needed to excel as an entrepreneur, get investors ready or launch and scale their business.",
    button: "#A37200",
    buttonLink: "/foundher-bootcamps",
    arrow: ochreArrow,
  },
];

const ourGrads = [
  {
    id: "Sab",
    youtube: "https://www.youtube.com/embed/-0RMb7OwnkE",
    bootcamp: "BLACKCODHER BOOTCAMP",
    name: "Sabrina",
    path: "https://youtu.be/-0RMb7OwnkE",
    story:
      "I joined the bootcamp because I had a passion for coding and I liked the idea that tech offers so much flexibility and a good work to life balance. I now got an offer at BT to become a software engineer.",
  },
  {
    id: "MikS",
    youtube: "https://www.youtube.com/embed/sUAx38UvfuY",
    bootcamp: "DATA BOOTCAMP",
    name: "Mikayla Sinead",
    path: "https://youtu.be/sUAx38UvfuY",
    story:
      "Because I am a fan of Niyo, I got interested in the data bootcamp. I enjoyed the accessibility of the course as it fit into my schedule.",
  },
  {
    id: "MasO",
    youtube: "https://www.youtube.com/embed/vlIlFWEwjrY",
    bootcamp: "Data BOOTCAMP",
    name: "Osahenomase Omoruyi",
    path: "https://youtu.be/vlIlFWEwjrY",
    story:
      "Having a degree in Biomedical Sciences, I have been working in NHS labs over the years and have recently transitioned into tech.",
  },
];

///////// OUR STORY

const ourStoryImages = {
  storyHero,
  aboutImage1,
  aboutImage2,
  supportLogo1,
  supportLogo2,
  supportLogo3,
  linkedin,
};

const upskillEvents = [
  {
    logo: upskillLogo1,
    title: "Career Bootcamps",
    text: "We partner with various organisations like Goldman Sachs, GCHQ, West Midlands Combined Authority, and other government departments to deliver 8-30 weeks bootcamps, for young people with the intention of improving their long-term career prospects.",
  },
  {
    logo: upskillLogo2,
    title: "FoundHer Bootcamps",
    text: "We host 8-12 week bootcamps for aspiring and founded  entrepreneurs, with disruptive ideas to facilitate sustainable and economic impact through their technology-driven business ideas.",
  },
  {
    logo: upskillLogo3,
    title: "Events",
    text: "We hold small to large scale events where we gather over 300 people to equip them with tangible skills that they can apply to their personal career or business journey.",
  },
  {
    logo: upskillLogo4,
    title: "Short Courses & Hackathons",
    text: "These are a series of global, immersive opportunities that give people the insights and a taste of what it is like to work in the tech industry.",
  },
];

const impactStats = [
  {
    logo: impactLogo1,
    number: "560",
    impact: "Peoplehave been trained by Niyo Bootcamp",
  },
  {
    logo: impactLogo1,
    number: "3,000",
    impact:
      "From various cities across the globe have applied for our bootcamps",
  },
  {
    logo: impactLogo1,
    number: "7,000",
    impact: "People internationally have been reached through our campaign.",
  },
];

const bootcampTeam = [
  {
    image: oyin,
    name: "Oyinkansola Adebayo",
    title: "Chief Executive Officer",
    linkedin: "https://uk.linkedin.com/in/oyinkansola-adebayo",
  },
  {
    image: laolu,
    name: "Olaolu Dada",
    title: "Chief Operating Officer",
    linkedin: "https://www.linkedin.com/in/olaoluwa-dada-622665122/",
  },
  {
    image: dami,
    name: "Dami Ladipo",
    title: "Head of Research & Organisational Development",
    linkedin: "",
  },

  {
    image: chinazor,
    name: "Chinazor Kalu",
    title: "Assistant Program Manager",
    linkedin: "https://www.linkedin.com/in/chinazorviviankalu/",
  },
  {
    image: sephora,
    name: "Sephora Amlan",
    title: "Partnerships & Employability Lead",
    linkedin: "",
  },

  {
    image: hannah,
    name: "Hannah Brewster",
    title: "Careers and Employability Coach",
    linkedin: "https://www.linkedin.com/in/hannah-b-brewster",
  },

  {
    image: victoria,
    name: "Victoria Sogbesan",
    title: "Assistant Program Manager",
    linkedin: "",
  },
];

const newsArticles = [
  {
    logo: voice,
    headline: "Initiative to boost numbers of women in...........",
    text: "In recent years diversity reports produced by tech giants such as Apple, Facebook, Google, and Microsoft have revealed one consistent fact about the industry as a whole – that the coders, engineers, and data scientists that drive it are overwhelmingly white and male.",
  },
  {
    logo: apple,
    headline: "The coding camp for women",
    text: "When it comes to the under-representation of women in the technology industry, “I have not seen much of a shift in the last decade”. This is the view of Charlene Hunter, the founder of Coding Females – a network for women in tech.",
  },
  {
    logo: bbc,
    headline: "The women in tech coding the future",
    text: "A unique 'boot camp' has been set up in the West Midlands to train women to become software developers. The aim is to increase digital skills and help create a more diverse workforce in technology, a traditionally male-dominated industry.",
  },
  {
    logo: voice,
    headline: "Initiative to boost numbers of women in...........",
    text: "In recent years diversity reports produced by tech giants such as Apple, Facebook, Google, and Microsoft have revealed one consistent fact about the industry as a whole – that the coders, engineers, and data scientists that drive it are overwhelmingly white and male.",
  },
  {
    logo: apple,
    headline: "The coding camp for women",
    text: "When it comes to the under-representation of women in the technology industry, “I have not seen much of a shift in the last decade”. This is the view of Charlene Hunter, the founder of Coding Females – a network for women in tech.",
  },
  {
    logo: bbc,
    headline: "The women in tech coding the future",
    text: "A unique 'boot camp' has been set up in the West Midlands to train women to become software developers. The aim is to increase digital skills and help create a more diverse workforce in technology, a traditionally male-dominated industry.",
  },
];

///////// PARTNERS

const partnersImages = {
  partnerHero,
};

const partnerCards = [
  {
    title: "HIRE TALENT",
    text: "Be part of committing to make proactive change within your industry. Our students are excellent candidates - they go through a rigorous process before gaining a place on the bootcamp. They are typically the top students out of over 400 applicants. By hiring them, you will invite diverse thinking and solutions in your organization.",
    spanWord: "HIRE",
    background: "#FBAF00",
    font: "#070909",
    buttonBg: "#070909",
    buttonLink: "/jobs",
    externalLink: "https://jobs.niyonetwork.com",
  },
  {
    title: "RUN YOUR OWN BOOTCAMP",
    text: "With our bespoke package, you can run your own bootcamp and recruit diverse, often overlooked candidates with the precise mix of tech skills your organisation needs. This will help as our students will bring fresh perspectives that widen your reach through building technologies and systems that cater to more diverse demographics. ",
    spanWord: "BOOTCAMP",
    background: "#070909",
    font: "#fff",
    buttonBg: "#FBAF00",
    buttonLink: "/partners-form",
  },
  {
    title: "ENGAGE WITH US",
    text: "Our mission is to foster the growth of young individuals in tech, by connecting them to the right resources and opportunities. We do this through mentorship networks, events, and an online community for support and collaboration.",
    spanWord: "ENGAGE",
    background: "#FBAF00",
    font: "#070909",
    buttonBg: "#070909",
    buttonLink: "/partners-form",
  },
];

const workBoxes = [
  {
    logo: workLogo1,
    title: "Hire Tech Talents",
    text: "Your organisation will have the opportunity to access our  tech talent pool, who have all graduated from one of our bootcamps.",
  },
  {
    logo: workLogo2,
    title: "Collaborate with us on an event or enrichment activity",
    text: "At Niyo, we take pride in creating high-quality and impactful events to inspire our tech community with intersections in the beauty and fashion industry. We would love to collaborate with your organisation on an event.",
  },
  {
    logo: workLogo3,
    title: "Run your own bootcamp",
    text: "Your organisation can choose to run your own bespoke bootcamp by partnering with us and being involved in the whole process from start to finish to get more talents into the organisation. This is for individuals at junior to senior level.",
  },
  {
    logo: workLogo3,
    title: "Run taster sprints and short courses",
    text: "We believe in building a pipeline of candidates and empowering those who are completely new to tech. Sponsor some short courses and  taster hackathons for talents.",
  },
  {
    logo: workLogo4,
    title: "Run a mentoring programme",
    text: "Mentoring our students to inspire more young individuals. At Niyo, we take pride in creating empowerment sessions that will support our learners with further technical training and improve their soft skills.",
  },
  {
    logo: workLogo5,
    title: "Advertise on our job board",
    text: "You can advertise roles on our job board, to over 7000+ talents in the UK and Africa.",
  },
];

const offeringCards = [
  {
    title: "Technology Bootcamp Junior Level",
    tag1: web2,
    points1: [
      "Digital Marketing",
      "Codher",
      "Data Analytics & Project Management",
      "Data Science & Machine Learning",
      "Cyber Bootcamp",
      "UX & UI Bootcamp",
      "Intro to Robotics Bootcamp",
    ],
    tag2: web3,
    points2: ["Immersive VR/AR/XR"],
  },
  {
    title: "Technology Bootcamp Mid Level",
    tag1: web2,
    points1: [
      "Software & Systems Architecture",
      "Data Science, AI & Machine Learning (Intermediate)",
      "Robotics Bootcamp",
      "Cyber Bootcamp",
      "Product Management",
    ],
    tag2: web3,
    points2: [
      "Build a web app on Near with Rust and Smart Contracts",
      "Build a web3 app on Ethereum with Solidity and Smart Contracts",
      "Build a web3 app on Solana with React & Rust",
      "UX & UI for web3",
      "Build a DAO with Javascript",
      "Create your own NFT collection with React and Solidity",
    ],
  },
  {
    title: "Technology Bootcamp Senior Level",
    tag1: web2,
    points1: [
      "Technical Architecture with Leadership",
      "AI & Data Science with Leadership",
    ],
  },
  {
    title: "Hair & Beaty Technology Bootcamp",
    points1: ["Afro hair bootcamp with digital entrepreneurship"],
  },
  {
    title: "Fashion Technology Bootcamp",
    points1: ["Launch your fashion brand on the metaverse from scratch"],
  },
  {
    title: "FoundHers Bootcamp",
    points1: [
      "Tech bootcamps for female founders",
      "No-code bootcamp for  female founders",
    ],
  },
];

///////// PARTNERS FORM

const jobLevels = [
  "Individual Contributor",
  "Manager",
  "Director",
  "VP+",
  "Executive",
];

const noEmployees = ["1-250", "251-5000", "5000+"];

///////// BOOTCAMPS

const BootcampImages = {
  bootcampHero,
  soon,
  web2Hero,
  web3Hero,
  hairHero,
  foundhersHero,
  fashionHero,
};

const web2BootcampInfo = [
  {
    span: "#E09D00",
    background: "#fff",
    image: web2One,
    title: "Software Development",
    path: "/software-dev",
    description:
      "The software development course is designed to provide participants with a comprehensive understanding of  the practical skills needed to develop software.",
    rating: rating,
    schedule: "9 hours/week | 16 weeks",
    level: "JUNIOR",
    buttonInfo: "View Bootcamp",
  },
  {
    span: "#E09D00",
    background: "#fff",
    image: web2Two,
    title: "Data Analytics & Project Management",
    path: "/data-course",
    externalLink: "",
    description:
      "Learn data analytics and project management from industry professionals. Gain hands-on experience with a challenging, real world project. ",
    rating: rating,
    schedule: "9 hours / week | 16 weeks",
    level: "JUNIOR",
    buttonInfo: "View Bootcamp",
  },
  
  {
    span: "#A37200",
    background: "#fff",
    color: "#070909",
    image: bigbreak,
    title: "Big Break Bootcamp",
    path: "/entrepreneur",
    externalLink: "",
    description:
      "Learn how to optimise your side hustle for your next BIG break. This bootcamp is aimed at those with business ideas, existing businesses, or those looking to embark on a business or freelance career, providing them with the crucial skills required to flourish in today's competitive marketplace; generally self employed individuals",
    rating: rating,
    schedule: "10 hours/week | 12 weeks",
    level: "",
    buttonInfo: "View Bootcamp",
  }
  // {
  //   span: "#E09D00",
  //   background: "#fff",
  //   image: web2Three,
  //   title: "Data Science, AI & Machine Learning",
  //   path: "",
  //   externalLink: "",
  //   description:
  //     "Become a world-class Data Scientist with one of our deep learning training programs. Gain practical experience in machine learning, AI and data science.",
  //   rating: rating,
  //   schedule: "5 hours / week | 16 weeks",
  //   level: "MID-LEVEL",
  //   buttonInfo: "Register Interest",
  // },
];

const web3BootcampInfo = [
  {
    span: "#070909",
    background: "#070909",
    color: "#fff",
    image: web3One,
    title: "VR & 3D Design",
    path: "/vr-coursee",
    externalLink: "",
    description:
      "Learn how to build the metaverse, build on top of this immutable foundation, and create your own augmented reality and virtual reality applications.",
    rating: rating,
    schedule: "5 hours/week  | 16 weeks",
    level: "JUNIOR",
    buttonInfo: "View Bootcamp",
  },
  {
    span: "#070909",
    background: "#070909",
    color: "#fff",
    image: web3Two,
    title: "Build a web app on Near with Rust and Smart Contracts",
    path: "",
    externalLink: "https://airtable.com/shrw6ZbgonNTa6POH",
    description:
      "This course will teach you how to build a web app on the Near blockchain using Rust and smart contracts. Imagine a world where data is transparent and accessible by anyone, anywhere.",
    rating: rating,
    schedule: "5 hours / week | 16 weeks",
    buttonInfo: "Register Interest",
  },
  {
    span: "#070909",
    background: "#070909",
    color: "#fff",
    image: web3Three,
    title: "Build a Web3 app on Ethereum with Solidity and Smart Contracts",
    path: "",
    externalLink: "https://airtable.com/shrw6ZbgonNTa6POH",
    description:
      "This course will teach you how to build a web app on the Ethereum blockchain using solidity and smart contracts. Imagine a world where data is transparent and accessible by anyone, anywhere.",
    rating: rating,
    schedule: "5 hours / week | 16 weeks",
    level: "MID-LEVEL",
    buttonInfo: "Register Interest",
  },
];

const hairBootcampInfo = [
  {
    span: "#FF47CE",
    background: "#fff",
    color: "#070909",
    image: hairOne,
    title: "Afro Hair with Digital Entrepreneurship",
    path: "/hair-beauty-course",
    externalLink: "",
    description:
      "At Afro Hair Bootcamp, we want to empower more Hairdressers to become certified and informed with business skills and training in order to make a difference on their own.",
    price: hairPrice1,
    rating: rating,
    schedule: "5 hours / week | 12 weeks",
    level: "HAIR & BEAUTY",
    buttonInfo: "View Bootcamp",
  },
];

const foundherBootcampInfo = [
  {
    span: "#A37200",
    background: "#fff",
    color: "#070909",
    image: foundhersOne,
    title: "Tech Bootcamp for Foundhers",
    path: "/foundher",
    externalLink: "",
    description:
      "We will help you understand various aspects of starting your business and how to grow it to build a community of female entrepreneurs and aspiring female founders.",
    rating: rating,
    schedule: "5 hours/week | 12 weeks",
    level: "JUNIOR",
    buttonInfo: "View Bootcamp",
  }
];

const BootcampFAQS = [
  {
    question:
      "I was part of a Niyo Bootcamp previously, can I also apply to a different one?    ",
    answer:
      "This is relative, we do not have a generic response because it could equate to duplication in funding for our publically funded bootcamps but not for bootcamps funded by our Employer Partners. Please email us at bootcamps@niyo.co for a more specific response.    ",
  },
  {
    question:
      "I clicked to apply, but it says you are no longer accepting applications",
    answer:
      "The reason would be that we are not recruiting at the time of your application. Kindly fill out our Interest Form and we will notify you when we resume another round of recruitment. You can also follow us on our socials (LinkedIn, Instagram, and Twitter @Niyobootcamps) and subscribe to our newsletter HERE    ",
  },
  {
    question: "Are there Tuition fees for learning on a Niyo Bootcamp?",
    answer:
      "Our courses are fully funded and you will not be expected to pay a tuition fee if your application is successful. You can subscribe to our newsletter HERE and register your interest HERE to be the first to know when we open applications for our next cohort.    ",
  },
  {
    question:
      "Are Niyo Bootcamps open in London or other locations - if not open yet",
    answer: `Please check each bootcamp description for the region it is being delivered within OR Please subscribe to our newsletter HERE and register your interest HERE to be the first to know when​ this happens.​ ​Be sure to follow us on LinkedIn and Instagram as well.`,
  },
  {
    question: "Applications are closed how do I register my interest    ",
    answer: "Please fill our Interest form for future cohorts here ",
  },
  {
    question:
      "My business is still in its' early stages / I am still developing my business idea, can I still apply?",
    answer: "xxxxxxxxxxxxxxxxxx",
  },
];

///////// INDIVIDUAL BOOTCAMP PAGES

const Web2BootcampsIndividual = [
  {
    span: "#E09D00",
    background: "#fff",
    image: web2One,
    title: "Software Development",
    path: "/software-dev",
    description:
      "The software development course is designed to provide participants with a comprehensive understanding of the fundamentals and practical skills needed to develop softwares.",
    rating: rating,
    schedule: "9 hours/week | 16 weeks",
    level: "",
    buttonInfo: "View Bootcamp",
  },
  {
    span: "#E09D00",
    background: "#fff",
    image: web2Two,
    title: "Data Analytics & Project Management",
    description:
      "Learn data analytics and project management from industry professionals. Gain hands-on experience with a challenging, real world project. ",
    rating: rating,
    schedule: "9 hours/week | 16 weeks",
    path: "/data-course",
    externalLink: "",
    buttonInfo: "View Bootcamp",
  },
  {
    span: "#A37200",
    background: "#fff",
    color: "#070909",
    image: bigbreak,
    title: "Big Break Bootcamp",
    path: "/entrepreneur",
    externalLink: "",
    description:
      "Learn how to optimise your side hustle for your next BIG break. This bootcamp is aimed at those with business ideas, existing businesses, or those looking to embark on a business or freelance career, providing them with the crucial skills required to flourish in today's competitive marketplace; generally self employed individuals",
    rating: rating,
    schedule: "10 hours/week | 12 weeks",
    level: "",
    buttonInfo: "View Bootcamp",
  },
  {
    span: "#A37200",
    background: "#fff",
    image: testing,
    title: "Software Testing",
    description:
      "With our expert instructors, you'll learn the latest tools and techniques for testing software effectively, efficiently, and thoroughly. ",
    rating: rating,
    schedule: "6 hours/week | 12 weeks",
    path: "/testing",
    buttonInfo: "View Bootcamp",
  },

  {
    span: "#E09D00",
    background: "#fff",
    image: digital,
    title: "Marketing for Digital Entrepreneurship",
    description:
      "Transform your digital business  by learning the latest strategies and techniques to drive results in today's digital landscape. From social media and email marketing to SEO, get hands-on experience with industry-relevant tools and platforms that will help you start & scale your digital business in 10 Weeks",
    rating: rating,
    schedule: "4 - 6 Hours / Week | 10 weeks ",
    path: "/marketing",
    buttonInfo: "View Bootcamp",
  },
];

const Web2BootcampsComingSoon = [
  {
    span: "#E09D00",
    background: "#fff",
    image: intel,
    title: "Data Science & Machine Learning",
    description:
      "This course is designed for those who want to gain the skills necessary to analyze data and make informed decisions, as well as to develop and deploy advanced machine learning models.",
    rating: rating,
    schedule: "",
    path: "",
    externalLink: "https://airtable.com/shrw6ZbgonNTa6POH",
    buttonInfo: "Register Interest",
  },
  ,
  {
    span: "#E09D00",
    background: "#fff",
    image: software,
    title: "Software & Systems Architecture",
    description:
      "Learn the best practices and techniques for designing scalable, secure, and maintainable software systems. Gain a deep understanding of architecture patterns, microservices, cloud computing, and more.",
    rating: rating,
    schedule: "9 hours/week | 16 weeks",
    path: "",
    externalLink: "https://airtable.com/shrw6ZbgonNTa6POH",
    buttonInfo: "Register Interest",
  },
  {
    span: "#E09D00",
    background: "#fff",
    image: cyber,
    title: "Cyber Bootcamp",
    description:
      "Unlock the secrets of a secure digital world! Our Cyber Security course contains how to protect yourself and your business from cyber threats. Learn from experts, dive into the latest technologies, and gain hands-on experience in real-world scenarios",
    rating: rating,
    schedule: "",
    path: "",
    externalLink: "https://airtable.com/shrw6ZbgonNTa6POH",
    buttonInfo: "Register Interest",
  },
  {
    span: "#E09D00",
    background: "#fff",
    image: UI,
    title: "UX & UI Bootcamp",
    description:
      "Unlock the secrets to designing user-friendly experiences. From research and prototyping to testing and launch, this course will equip you with the skills and knowledge you need to take your designs to the next level.",
    rating: rating,
    schedule: "",
    path: "",
    externalLink: "https://airtable.com/shrw6ZbgonNTa6POH",
    buttonInfo: "Register Interest",
  },
  {
    span: "#E09D00",
    background: "#fff",
    image: robotics,
    title: "Intro to Robotics Bootcamp",
    description:
      "Step into the future of technology with our Introduction to Robotics course. From basics of mechanics and electronics to programming and control systems, You'll also have the opportunity to build and program your own robots!",
    rating: rating,
    schedule: "",
    path: "",
    externalLink: "https://airtable.com/shrw6ZbgonNTa6POH",
    buttonInfo: "Register Interest",
  },

  {
    span: "#E09D00",
    background: "#fff",
    image: product,
    title: "Product Management",
    description:
      "Learn how to bring new and innovative products to market and drive business success. From idea generation to market launch, you'll gain a comprehensive understanding of the product management process.",
    rating: rating,
    schedule: "",
    path: "",
    externalLink: "https://airtable.com/shrw6ZbgonNTa6POH",
    buttonInfo: "Register Interest",
  },
  {
    span: "#E09D00",
    background: "#fff",
    image: architecture,
    title: "Technical Architecture with Leadership",
    description:
      "Learn the best practices in architecture design, project management, and leadership. Gain a solid understanding of the technical and business aspects of software development and build your expertise in leading cross-functional teams.",
    rating: rating,
    schedule: "",
    path: "",
    externalLink: "https://airtable.com/shrw6ZbgonNTa6POH",
    buttonInfo: "Register Interest",
  },
  // {
  //   span: "#E09D00",
  //   background: "#fff",
  //   image: web2One,
  //   title: "AI & Data Science with Leadership",
  //   description:
  //     "A part time full stack development course, covering front and backend development, culminating in your own fully functioning web application.",
  //   rating: rating,
  //   schedule: "",
  //   path: "",
  //   externalLink: "",
  //   buttonInfo: "Register Interest",
  // },
];

const Web3BootcampsIndividual = [
  {
    span: "#E09D00",
    background: "#191A1A",
    color: "#fff",
    image: web3One,
    title: "VR & 3D Design",
    description:
      "Learn how to build the metaverse, build on top of this immutable foundation, and create your own augmented reality and virtual reality applications.",
    rating: rating,
    schedule: "5 hours/week | 16 weeks",
    path: "/vr-coursee",
    externalLink: "",
    buttonInfo: "View Bootcamp",
  },
];

const Web3BootcampsComingSoon = [
  {
    span: "#E09D00",
    background: "#191A1A",
    color: "#fff",
    image: web3Two,
    title: "Build a Web App on Near with Rust and Smart Contracts",
    description:
      "This course will teach you how to build a web app on the Near blockchain using Rust and smart contracts. Imagine a world where data is transparent and accessible by anyone, anywhere.",
    rating: rating,
    schedule: "",
    path: "",
    externalLink: "https://airtable.com/shrw6ZbgonNTa6POH",
    buttonInfo: "Register Interest",
  },
  {
    span: "#E09D00",
    background: "#191A1A",
    color: "#fff",
    image: web3Three,
    title: "Build a Web3 App on Ethereum with Solidity and Smart Contracts",
    description:
      "This course will teach you how to build a web app on the Ethereum blockchain using solidity and smart contracts. Imagine a world where data is transparent and accessible by anyone, anywhere.",
    rating: rating,
    schedule: "",
    path: "",
    externalLink: "https://airtable.com/shrw6ZbgonNTa6POH",
    buttonInfo: "Register Interest",
  },
  {
    span: "#E09D00",
    background: "#191A1A",
    color: "#fff",
    image: web4,
    title: "Build a Web3 App on Solana with React + Rust",
    description:
      " Learn to create fast, scalable decentralized applications on the Solana blockchain. Explore the power of React and Rust, and gain hands-on experience with real-world projects. ",
    rating: rating,
    schedule: " ",
    path: "",
    externalLink: "https://airtable.com/shrw6ZbgonNTa6POH",
    buttonInfo: "Register Interest",
  },
  {
    span: "#E09D00",
    background: "#191A1A",
    color: "#fff",
    image: design,
    title: "UX & UI for Web3 Bootcamp",
    description:
      "Learn the latest design trends and methodologies, explore new tools, and get hands-on experience with real projects. Elevate your design skills to the next level and take your place at the forefront of the web3 revolution.",
    rating: rating,
    schedule: "",
    path: "",
    externalLink: "https://airtable.com/shrw6ZbgonNTa6POH",
    buttonInfo: "Register Interest",
  },
  {
    span: "#E09D00",
    background: "#191A1A",
    color: "#fff",
    image: dao,
    title: "Building a DAO with Javascript",
    description:
      "Explore the potential of smart contracts and dive into the world of decentralized governance. Gain hands-on experience with practical projects and master the skills to build truly decentralized applications.",
    rating: rating,
    schedule: "",
    path: "",
    externalLink: "https://airtable.com/shrw6ZbgonNTa6POH",
    buttonInfo: "Register Interest",
  },
  {
    span: "#E09D00",
    background: "#191A1A",
    color: "#fff",
    image: nft,
    title: "Create your own NFT collection with React and Solidity",
    description:
      "Dive into the world of blockchain and discover the limitless potential of non-fungible tokens (NFTs). Gain hands-on experience with cutting-edge technologies and bring your ideas to life.",
    rating: rating,
    schedule: "",
    path: "",
    externalLink: "https://airtable.com/shrw6ZbgonNTa6POH",
    buttonInfo: "Register Interest",
  },
];

const HairBootcampsIndividual = [
  {
    span: "#FF47CE",
    background: "#fff",
    image: hairOne,
    title: "Resident Afro Hair  Stylist Trainee",
    description:
      "We are looking to train and hire excellent stylists with creative flare and business tact for our AR-Powered Salon.",
    rating: rating,
    schedule: "5 hours/week | 12 weeks",
    path: "/hair-beauty-course",
    externalLink: "",
    buttonInfo: "View Bootcamp",
  },
];

const HairBootcampsComingSoon = [
  // {
  //   span: "#FF47CE",
  //   background: "#fff",
  //   image: web2One,
  //   title: "",
  //   description:
  //     "",
  //   rating: rating,
  //   schedule: "",
  //   path: "",
  // externalLink: "",
  //   buttonInfo: "Register Interest",
  // },
];

const FoundhersBootcampsIndividual = [
  {
    span: "#A37200",
    background: "#fff",
    image: foundhersOne,
    title: "Tech Bootcamp for Foundhers",
    description:
      "We will help you understand various aspects of starting your business and how to grow it to build a community of female entrepreneurs and aspiring female founders",
    rating: rating,
    schedule: "5 hours/week | 12 weeks",
    path: "/foundher",
    externalLink: "",
    buttonInfo: "View Bootcamp",
  },
];

// const FoundhersBootcampsComingSoon = [
//   {
//     span: "#A37200",
//     background: "#fff",
//     image: web2One,
//     title: "No-Code Bootcamp for Foundhers",
//     description:
//       "A part time full stack development course, covering front and backend development, culminating in your own fully functioning web application.",
//     rating: rating,
//     schedule: "5 hours/week | 16 weeks | Pre-Junior",
//     path: "",
//     externalLink: "",
//     buttonInfo: "Register Interest",
//   },
// ];

const FashionBootcampsIndividual = [
  {
    span: "#E09D00",
    background: "#fff",
    image: web2One,
    title: "Fashion Bootcamp",
    description:
      "A part time full stack development course, covering front and backend development, culminating in your own fully functioning web application.",
    rating: rating,
    schedule: "5 hours/week | 16 weeks",
    path: "",
    externalLink: "",
    buttonInfo: "View Bootcamp",
  },
];

const FashionBootcampsComingSoon = [
  {
    span: "#E09D00",
    background: "#fff",
    image: web2One,
    title: "Fashion Bootcamp",
    description:
      "A part time full stack development course, covering front and backend development, culminating in your own fully functioning web application.",
    rating: rating,
    schedule: "5 hours/week | 16 weeks",
    path: "",
    externalLink: "",
    buttonInfo: "Register Interest",
  },
];

///////// REGISTRATION

const SpecificBenefit = [
  "Job Seekers Allowance",
  "Universal Credit",
  "Employment and Support Allowance",
  "Other Benefits",
];

const FormTimelineImages = [number1Dark, number2Dark, number3Dark];
const CurrentEmploymentStatus = [
  "Full- time Employed",
  "In employment - Zero hour contract",
  "In training/education",
  "Part-time Employed",
  "Self-Employed",
  "Student",
  "Unemployed",
  "Unemployed - Less than 12 months",
  "Unemployed - 12 months or more",
  "Not working - Care responsibilities",
  "Not working - Long term sickness",
  "Retired",
  "Others",
];
const ContinueWork = [
  "Yes",
  "No",
  "Yes - Self Employed",
  "Yes - Part Time",
  "Yes - Full Time",
];
const SpecificDisability = [
  "ADHD",
  "Dyslexia",
  "Depression",
  "CPTSD",
  "Arthritis",
  "Anxiety",
  "Panic Attack",
  "Mental Health",
  "Nausea",
  "Dyscalculia",
  "Asthma",
  "Sickle Cell",
  "Mobility",
  "Fracture",
  "Spinal Injury",
  "Hyperacusis",
  "Fibromyalgia",
  "Others",
];

const CurrentSalary = [
  "Less than £18k",
  "£18k - £21k",
  "£21k - £25k",
  "£25k - £30k",
  "More than £30k",
  "Weekly/Hourly",
];

const Industry = [
  "Financial Services/Banking.",
  "Retail/E-Commerce.",
  "Agriculture/Forestry/Fishing.",
  " Construction/Real Estate.",
  "Utility: Energy & Water.",
  "Hospitality/Hotel/Restaurant.",
  "Health/Humanity.",
  "Engineering.",
  "Technology.",
  "Legal/Compliance/Governance.",
  "Arts/Media & Communications.",
  "Others",
];

const ChooseJobRole = [
  "Administrative/Secretatrial.",
  "Caring/Leisure/Service.",
  "Sales/Customer Service.",
  "Professional Occupation.",
  "Health Care/Domiciliary.",
  "Account/Finance/Banking.",
  "Quality Control.",
  "Programmes/Project/Product and Delivery Coordinator.",
  "Others",
];

const HoursWorked = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",

  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "Others",
];

const StudyLevel = [
  "Entry Level: Entry Level Award, Entry Level Certificate (ELC), Entry Level Diploma, Entry Level English for speakers of other languages (ESOL), Entry Level Essential Skills, Entry Level Functional Skills, Skills for Life.",
  "Level 1: First Certificate, GCSE (Grades 3, 2, 1 or D,E,F,G) Level 1 Award, Level 1 Certificate, Level 1 Diploma, Level 1 ESOL, Level 1 Essential Skills, Level 1 Functional Skills, Level 1 National Vocational Qualification (NVQ), Music Grades 1, 2, and 3.",
  "Level 2: Intermediate Apprenticeship, GCSE (Grades 9,8,7,6,5,4,A*,A,B,C), Level2 Award, Level2 Certificate, Level2 Diploma, Level2 ESOL, Level2 Essential Skills, Level2 Functional Skills, Level2 National Diploma, Level2 NVQ, Music Grade 4&5,O Level A,B,C.",
  "Level 3: A Level, Access to Higher Education Diploma, A Apprenticeship, Applied General, AS Level, Int'l Baccalaureate Diploma, Level3 Award, Certificate, Diploma, ESOL, National Certificate, National Diploma, NVQ, MusicGrades6,7,8, T Level, Tech Level.",
  "Level 4: Certificate of Higher Education (CertHE), Higher Apprenticeship, Higher National Certificate (HNC), Level 4 Award, Level 4 Certificate, Level 4 Diploma, Level 4 NVQ.",
  "Level 5: Diploma of Higher Education (DipHE), Foundation Degree, Higher National Diploma (HND), Level 5 Award, Level 5 Certificate, Level 5 Diploma, Level 5 NVQ.",
  "Level 6: Degree Apprenticeship, Degree with Honours (Bachelor of Arts (BA) Hons, Bachelor of Science (B. Sc) Hons), Graduate Certificate, Graduate Diploma, Level 6 Award, Level 6 Certificate, Level 6 Diploma, Level 6 NVQ, Ordinary Degree Without Honours.",
  "Level 7: Integrated Master's Degree (Example: MEng), Level 7 Award, Level 7 Certificate, Level 7 Diploma, Level 7 NVQ, Master's Degree (Example - Master of Arts (MA), Master of Science (M. Sc), Postgraduate Certificate, Postgraduate Diploma, PGCE.",
  "Level 8: Doctorate (Example: Doctor of Philosophy (PhD or DPhil), Level 8 Award, Level 8 Certificate, Level 8 Diploma.",
];

const AgeGroups = [
  "16 - 24",
  "25 - 34",
  "35 - 44",
  "45 - 54",
  "55 - 64",
  "65 or above",
];

const Subject = [
  "Arts",
  "Business and Management",
  "Combined General Studies",
  "Communication and Media",
  "Education and Teaching",
  "STEM",
  "Geographical and Environmental Studies",
  "Humanities",
  "Languages",
  "Law",
  "Media & Communications",
  "Social Sciences",
  "Health Care",
];

const applyingPrior = [
  "Agriculture, Forestry and Fishing",
  "Mining and Quarrying",
  "Manufacturing",
  "Electricity, gas, steam and air conditioning supply",
  "Water supply, sewerage, waste management and remediation activities",
  "Construction",
  "Wholesale and retail trade; repair of motor vehicles and motorcycles",
  "Transportation and storage",
  "Information and communication",
  "Financial and insurance activities",
  "Real estate activities",
  "Professional, scientific and technical activities",
  "Administrative and support service activities",
  "Education",
  "Human health and social work activities",
  "Arts, entertainment and recreation",
  "Other service activities",
];

const employementStatus = [
  "In full - time employment",
  "In part - time employment",
  "Employed - zero - hour contract",
  "Self - employed",
  "Unemployed - less than 12 months",
  "Unemployed - 12 months or more",
  "In training / education ",
  "Retired",
  "Not working - long - term sickness",
  "Not working - for caring responsibilities",
  "Prisoner",
];

const TechCareer = [
  "Arts",
  "Business and Management",
  "Combined General Studies",
  "Communication and Media",
  "Education and Teaching",
  "STEM",
  "Geographical and Environmental Studies",
  "Humanities",
  "Languages",
  "Law",
  "Media & Communications",
  "Social Sciences",
  "Health Care",
];

const Ethnicities = [
  "- Carribean",
  "- African",
  "Mixed - Carribean & White ",
  "Mixed - African & White ",
  "Mixed - African/Carribean & Arab",
  "Mixed - African/Carribean & Asian",
];

const applyEthnicities = [
  "White British",
  "All other white",
  "Mixed / multiple ethnic groups",
  "Asian / Asian British",
  "/ African / Caribbean / British",
  "Other ethnic group",
  "Prefer not to say",
];

const UploadButtons = [uploadButton1, uploadButton2];
const MediaMethods = [
  "The National Careers Service",
  "FE college or training provider",
  "Current employer",
  "Job Centre Plus / Work Coach / DWP",
  "Family, friend or colleague",
  "Gov.UK website",
  "Social Media",
  "Newspaper / magazine article",
  "University",
  "Job Sites(e.g.Indeed, Reed, etc.)",
  "Other",
];

const MyCheckBoxList = [
  {
    order: 0,
    name: "Tech is the immediate future of work, I want to remain employable",
  },
  {
    order: 1,
    name: "Tech is the immediate future of work, I want to get promotion in my place of work",
  },
  {
    order: 2,
    name: "Tech is the immediate future of work, I want to change roles in my organisation",
  },
  {
    order: 3,
    name: "Tech is the immediate future of work, I want to change jobs",
  },
  {
    order: 4,
    name: "Tech is the immediate future of work, I want the flexibilities Tech afford because of my family",
  },
  {
    order: 5,
    name: "Tech is the immediate future of work, I want a higher pay",
  },
];

const BootcampWindow = [
  "January 2023",
  "Febuary 2023",
  "March 2023",
  "September 2023",
  "May 2023",
];

const RoleTitle = [
  "Founder",
  "Co-Founder",
  "CEO",
  "COO",
  "CFO",
  "CTO",
  "Others",
];

const NoFoundher = [
  "Just Myself.",
  "Myself and 1",
  "Myself and 2",
  "Myself and 3+",
];

const TeamMembers = ["0 – 2", "3 – 5", "6 – 10", "11+"];

const TeamMembersParticaption = [
  "Just Myself",
  "Myself and 2 team Members",
  "Myself and 3+ Team Members",
];

const BusinessStructure = [
  "Sole Trader",
  "Partnership",
  "Limited Company",
  "Limited Liability Company",
  "Others",
];

const FundingReceived = [
  "None",
  "Under £50K Per Annum",
  "£50K - £100K Per Annum",
  "£100K - £250K Per Annum",
  "Over £250K Per Annum",
];

const BusinessSector = [
  "Agriculture",
  "Arts & Culture",
  "Defence & Aerospace",
  "Education & Training",
  "Energy",
  "Entertainment",
  "Finance",
  "Hair & Beauty",
  "Technology",
];

const BusinessStage = [
  "Idea Stage",
  "MVP/Pre-Seed",
  "Pre-Revenue",
  "Revenue Generating",
  "Profit Generating",
];

const TechnologyUse = ["Very High", "High", "Medium", "Low", "Very Low"];

///////// COMMUNITY

const CommunityImages = [communityArrow];

const CommunityData = [
  {
    image: slackImage,
    title: "WhatsApp",
    description:
      "Get tips and advice from professionals and bootcamp graduates  and  access exclusive job and freelance opportunities.",
    buttonText: `Join Niyo Family`,
    icon: "",
    externalLink:
      "https://chat.whatsapp.com/BikOghd2Wi490bHs3J8ENq",
  },
  {
    image: jobsImage,
    title: "Jobs",
    description: "Start your journey by applying for available roles.",
    buttonText: "View job board",

    externalLink: "https://bootcamps.niyo.co/jobs",
  },
  {
    image: eventsImage,
    title: "Events",
    description:
      "Attend our events to connect and networks with other colleagues.",
    buttonText: "View Events",
    icon: "",
    externalLink: "https://events.niyo.co/",
  },
  {
    image: blogsImage,
    title: "Blogs",
    description:
      "Stay ahead with stories, insight and news from Niyo Bootcamps.",
    icon: "",
    buttonText: "View Blogs",
    externalLink: "https://medium.com/niyogroup",
  },
];

///////// INDIVIUAL BOOTCAMPS////////////////////////

///  rand comment const IndividualMarketing = { bootcampVideo, week1, syllabusIcon };

const IndividualMarketing = { bootcampVideo, week1, syllabusIcon };
const MarketingInfo = {
  courseTitle: "Marketing for Digital Entrepreneurship",
  schedule: "4 - 6 Hours / Week | 10 weeks. ",
  applicationLink: "https://zi7e33fld49.typeform.com/niyobootcamps",
  courseRating: rating,
  headingDescription:
    "The modules are designed to take the learners through a process of building and testing digital products with effective marketing skills  that enable you to scale sales. ",
  heroImage: foundhersBackground,
  cardTitle: "MARKETING BOOTCAMP",
  overviewHeading: "Transform your digital business",
  overviewText:
    "Transform your digital business  by learning the latest strategies and techniques to drive results in today's digital landscape. From social media and email marketing to SEO, get hands-on experience with industry-relevant tools and platforms that will help you start & scale your digital business in 10 Weeks",
  overviewVideo: "https://www.youtube.com/embed/vlIlFWEwjrY?si=SGikFzcz8SAmWVy4",
  syllabusText:
    "This bootcamp will provide you with access to mentorship and high-value content needed to excel as an entrepreneur. The NIYO Marketing for Digital Entrepreneurship Bootcamp is a 10-week business accelerator designed to provide individuals access to mentorship, finance, and high-value content needed to excel as a digital entrepreneur.",
  syllabusLessons: [
    {
      weekNumber: "Module 1",
      weekTitle: "Your Idea",
      content: [
        "The Dream (Ideation): Vision 20 &  Understanding your Digital Business Idea",
        "Choosing Your Business Model & Finding your Niche",
        "Market Research & Product Research",
      ],
    },
    {
      weekNumber: "Module 2",
      weekTitle: " Marketing for Digital business",
      content: [
        "Digital Products:  How to position them & Sell them",
        "Mastering brand visibility & SEO",
        "Generating Product demand & Digital Ads ",
        "Pricing: Create your price menu and understanding your profit margins",
        "Creating Sales Funnels / Pricing packages",
      ],
    },
    {
      weekNumber: "Module 3",
      weekTitle: "Make it Official ",
      content: [
        "Registering your Business ",
        "Mastering your legal policies, insurance and accounts",
        "Using technology to leverage your business - management systems",
        "Digital Business Demo Evening",
      ],
    },
  ],
  benefits: [
    " Chance to learn on a 10week interactive intensive hybrid programme designed for individuals looking to start digital/ ecom businesses ",
    "An intimate supportive community of  business-minded people.",
    "Access to mentorship, finance, and high-value content needed to excel as an entrepreneur and get investor ready.",
    "Opportunity to learn about the newest effective marketing tools to grow their business and learn about ways to access funding and investment.",
    "Chance to Pitch and be introduced to Finance Investors",
  ],

  postBenefits: [
    "Reference Letter from the Management of Programmes at Niyo Bootcamps",
    "Completion of a projects to put on your profile (proof of skillset)",
    "Linkedin Badge of completion for you professional profile",
    "Fast-track with any Employer partners of Niyo you apply to work with."
  ],
  eventsText:
    "Our EmpowHerment Evenings aims to inspire, support, educate, empower you to develop the soft skills needed to excel as entrepreneurs. It features sessions on managing your time effectively as an entrepreneur, building self leadership and awareness, ensuring your legals are water tight and so on.",
  requirementsText:
    "Please note this opportunity is open to candidates based in England only. However, the expectation is that you are either a West Midlands resident or willing to register your business within the West Midlands. You will be expected to show proof of this if selected",
  requirementsList: [
    "FoundHers based in the England.",
    "Aged 19 or over  (you can be 18 years old as long as you turn 19 before 31st August 2022).",
    "You can commit fully to the programme (3-6 hours a week).",
    "Looking to developing a marketing edge for their digital business  or looking for how to use Tech to scale (in delivery, quality assurance or operations).",
    "For people looking to earn income with a digital business Must be less than 2 years in business.",
  ],
  tutorsParagraph:
    "We have experienced tutors from the Tech industry. We also partner with Industry leaders such as Microsoft, KPMG etc. Experienced personnel from these partners teach our students on the bootcamp. This opens more opportunities for students to network with and learn from highly experienced Tech professionals.",
  tutors: [],
};
const IndividualFoundhers = { bootcampVideo, week1, syllabusIcon };
const FoundhersInfo = {
  courseTitle: "Tech Bootcamps for  Foundhers",
  schedule: "4 Hours / Week | 12 weeks ",
  applicationLink: "https://zi7e33fld49.typeform.com/niyobootcamps",
  courseRating: rating,
  headingDescription:
    "The NIYO FoundHer bootcamp is a 12-week business accelerator designed to provide FoundHers access to mentorship, finance, and high-value content needed to excel as an entrepreneur, get investors ready or launch and scale their business.",
  heroImage: foundhersBackground,
  cardTitle: "FOUNDERS BOOTCAMP",
  overviewHeading:
    "Get access to mentorship, finance, and high-value content needed to excel as a Foundher.",
  overviewText:
    "The NIYO FoundHer bootcamp is a 12-week business accelerator designed to provide FoundHers access to mentorship, finance, and high-value content needed to excel as an entrepreneur, get investors ready or launch and scale their business.",
  overviewVideo: "https://www.youtube.com/embed/vlIlFWEwjrY?si=SGikFzcz8SAmWVy4",
  syllabusText:
    "The NIYO FoundHer bootcamp is a 12-week business accelerator designed to provide FoundHers access to mentorship, finance, and high-value content needed to excel as an entrepreneur, get investors ready or launch and scale their business.",
  syllabusLessons: [
    {
      weekNumber: "Module 1",
      weekTitle: "The Entrepreneur's Dream (Ideation)",
      content: [
        "Where will you be in 3 years time?",
        "The Story starts with ‘YOU’",
        "What’s your ‘Why?’",
        "The BIG Idea - Your vision and mission",
      ],
    },
    {
      weekNumber: "Module 2",
      weekTitle: "Understanding Your Big Idea",
      content: [
        "Who, What, When, Where, Why? How?",
        "PSSP Framework ",
        "The process of Validating your Idea",
      ],
    },
    {
      weekNumber: "Module 3",
      weekTitle: "Your Business Model",
      content: [
        "Business Models B2B , B2C , SAAS , MLM, E-COM, Franchises",
        "Digital Business Models:  Freemium, Subscription, On demand , Open",
        "What is a Lean Model Canvas?",
        "How to use it",
        "Explore and find out how to test each component of the Lean Model Canvas.",
        "High Level Concept: Your Business model in the Lean Model Canvas",
      ],
    },
    {
      weekNumber: "Module 4",
      weekTitle: "Market Research",
      content: [
        "How to Talk to your Customers or Service Users",
        "The Mom Test",
        "What will it take to go to market?",
        "TAM , SOM ,SAM",
        "Product Market Fit",
      ],
    },
    {
      weekNumber: "Module 5",
      weekTitle: "Product Development & The Success of your MVP",
      content: [
        "Stages of Product development/Product Mapping",
        "How to build  an MVP",
        "Setting KPI’s for your product",
        "How to build your MVP Quickly",
        "The Analytics of a successful MVP",
        "Key Markers you need to develop your MVP",
        "Product Funnels",
      ],
    },
    {
      weekNumber: "Module 6",
      weekTitle: "Brand Identity",
      content: [
        "Brand Positioning",
        "Brand Name",
        "Brand Interaction  & Brand Placement",
        "Lead Collection Systems/ Growing your mailing lists",
        "Creative thinking ",
        "Brand Association",
        "Minimum Viable Brand",
      ],
    },
    {
      weekNumber: "Module 7",
      weekTitle: "Build your MVP(Practical sessions & Sponsored Hackathon)",
      content: ["Design Thinking", "UI - User Interface"],
    },
    {
      weekNumber: "Module 8",
      weekTitle: "Marketing & Growth Hacking",
      content: [
        "Social Media Strategy and social media writing , Growth Hacking",
        "Digital Marketing Strategy and Digital Advertising",
        "Get Visible on Google and Find Customers on Google Maps",
        "Get Started with Analytics and Youtube for your Brand",
        "Create Videos for your Youtube and Set Up and Grow your Charity online",
      ],
    },
    {
      weekNumber: "Module 9",
      weekTitle: "Funnels",
      content: [
        "What is a Funnel?",
        "AIDA Model ",
        "The Foundations of every Digital Marketing Campaign",
        "Lead Generation & CAC (Customer Acquisition Cost)",
        "SEO",
      ],
    },
    {
      weekNumber: "Module 10",
      weekTitle: "Pricing Strategy",
      content: [
        "Value Ladder & Pricing Strategy",
        "Upsales, Add-ons and Cross sales",
      ],
    },
    {
      weekNumber: "Module 11",
      weekTitle: "Selling with PR",
      content: [
        "Press Hacking & PR",
        "Building relationships with journalists",
        "Press Training",
      ],
    },
    {
      weekNumber: "Module 12",
      weekTitle: "Risk Management, Systems and Processes",
      content: [],
    },
    {
      weekNumber: "Module 13",
      weekTitle: "Finance, Fundraising and legals ",
      content: ["Company Formation", "Co-founders and the legals"],
    },
    {
      weekNumber: "Module 14",
      weekTitle: "Pitching ",
      content: [
        "Creating a Pitch Deck",
        "Pitching to Corporate Spaces",
        "Pitching to VCs",
      ],
    },
  ],
  benefits: [
    "Chance to learn on a 12week interactive intensive hybrid programme designed for FoundHers on the verge of going to market.",
    "An intimate supportive community of FoundHers.",
    "Access to mentorship, finance, and high-value content needed to excel as an entrepreneur and get investor ready.",
    "Opportunity to learn from Industry experts and Entrepreneurs with years of experience.",
    "Tools to grow their business and learn about ways to access funding and investment.",
    "Chance to pitch & Win £5,000 in our Niyo FoundHer Business Pitching competition.",
  ],

  postBenefits: [
    "Reference Letter from the Management of Programmes at Niyo Bootcamps",
    "Completion of a projects to put on your profile (proof of skillset)",
    "Linkedin Badge of completion for you professional profile",
    "Fast-track with any Employer partners of Niyo you apply to work with."
  ],
  eventsText:
    "Our EmpowHerment Evenings aims to inspire, support, educate, empower you to develop the soft skills needed to excel as entrepreneurs. It features sessions on managing your time effectively as an entrepreneur, building self leadership and awareness, ensuring your legals are water tight and so on.",
  requirementsText:
    "Please note this opportunity is open to candidates based in England only. However, the expectation is that you are either a West Midlands resident or willing to register your business within the West Midlands. You will be expected to show proof of this if selected",
  requirementsList: [
    "FoundHers based in the England.",
    "Aged 19 or over  (you can be 18 years old as long as you turn 19 before 31st August 2022).",
    "You can commit fully to the programme (3-5 hours a week).",
    "Businesses developing creative Tech solution or looking for how to use Tech to scale (in delivery, quality assurance or operations).",
    "For Startup businesses or FoundHers with ideas that could go to market within 6 months.",
    "Must be less than 2 years in business.",
  ],
  tutorsParagraph: "",
  tutors: [
    {
      tutorImage: tutorOyin,
      tutorName: "Oyinkansola Adebayo",
      tutorTitle: "Chief Executive Officer",
      tutorDescription:
        "Oyin will be teaching at the bootcamp on understanding your business idea and building your brand identity.",
    },
    {
      tutorImage: tutorLaolu,
      tutorName: "Laolu Dada",
      tutorTitle: "COO at Niyo Group",
      tutorDescription:
        "Laolu will be teaching on building your brand’s identity and MVP.",
    },
    {
      tutorImage: tutorDaniella,
      tutorName: "Daniella Genas",
      tutorTitle: "Founder of She's The Boss International",
      tutorDescription:
        "Daniella will be teaching participants how they can go from ideation to executing their business idea seamlessly. Expert in innovation, strategy and operations.",
    },
    {
      tutorImage: tutorPeace,
      tutorName: "Peace Itemi",
      tutorTitle: "Digital Marketing Expert & Trainer",
      tutorDescription:
        "Peace will be teaching participants marketing strategies and growth hacks to grow their business.",
    },
    {
      tutorImage: tutorAmon,
      tutorName: "Amon Kipglat",
      tutorTitle: "Founder of She's The Boss International",
      tutorDescription:
        "Amon will be teaching participants how to execute market research seamlessly and achieve product market fit.",
    },
    {
      tutorImage: tutorTru,
      tutorName: "Tru Powell",
      tutorTitle: "Publicity Coach and Personal Brand Strategist",
      tutorDescription:
        "Tru knows exactly what you need to do to get your brand’s name out there! He will be teaching you how to hack the press & PR, build relationships with journalists and press training.",
    },
  ],
};

const IndividualSoftware = { bootcampVideo };
const SoftwareInfo = {
  courseTitle: "Software & Systems Architecture",
  schedule: "10 hours / Week",
  courseRating: rating,
  applicationLink: "https://zi7e33fld49.typeform.com/niyobootcamps",
  headingDescription:
    "This bootcamp is a 30-week bootcamp is designed to introduce students from all backgrounds — including, without limitation, and other ethnic minority groups, who are in junior Tech roles, to progress into mid-level and senior roles as DevOps, Solutions Architect, Software Architects and Software Developers.",
  heroImage: softwareBackground,
  cardTitle: "TECHNICAL BOOTCAMP",
  overviewHeading: "Advance your career, become a Software Architect",
  overviewText:
    "This bootcamp is a 30-week bootcamp is designed to introduce students from all backgrounds — including, without limitation, and other ethnic minority groups, who are in junior Tech roles, to progress into mid-level and senior roles as DevOps, Solutions Architect, Software Architects and Software Developers.",
  overviewVideo: "https://www.youtube.com/embed/vlIlFWEwjrY?si=SGikFzcz8SAmWVy4",
  syllabusText:
    "This bootcamp is a 30-week bootcamp is designed to introduce students from all backgrounds — including, without limitation, and other ethnic minority groups, who are in junior Tech roles, to progress into mid-level and senior roles as DevOps, Solutions Architect, Software Architects and Software Developers.",
  syllabusLessons: [
    {
      weekNumber: "Module 1",
      weekTitle:
        "Introduction to design principles  & how to choose a design principle",
      content: [
        "Object Orientated Design (OOD)",
        " Test Driven Development (TDD)",
        " Behaviour Driven Development (BDD)",
        "Domain Driven Development (DDD)",
        "Choosing a Design Principle",
      ],
    },
    {
      weekNumber: "Module 2",
      weekTitle: "Fundamentals of coding with python",
      content: [
        "Intro to Python",
        "What is Python and what is it used for? ",
        "Understanding Types in Python ",
        "Using Operators in Python ",
        "Variables ",
        "Collections - Lists, Dictionaries",
        "Conditional Logic and Loops ",
        " Functions ",
        " Fetching data from APIs",
      ],
    },
    {
      weekNumber: "Module 3",
      weekTitle: "Web  Application & Software Architecture",
      content: [
        "Different tiers in software architecture",
        "Web Architecture : What is a Rest API?",
        "Web Architecture: HTTPS Push & Pull ",
        " Web Architecture: Client side and Server side rendering",
        " Scalability",
        "High availability",
        " Load Balancing",
        "Monolith & Microservices",
        "Micro Frontends",
        " Databases",
        "Caching",
        "Message Queue",
        "Stream processing",
        "Peer-Peer Architecture",
        "Architecture for mobile apps",
      ],
    },
    {
      weekNumber: "Module 4",
      weekTitle: "Building Backend REST API with Python",
      content: [
        "What is unit testing?",
        "Writing a simple unit test",
        "Writing a Unit test with TDD",
        "Python virtual environments",
        "Setting up Pytests with pycharm",
        " Setting up Pytests in Eclipse Pydev",
        "Running Pytests: Test discovery",
        "Running Pytests: xUnit Style Setup  and Teardown",
        "Running Pytests: Test Fixtures",
        "Running Pytests: Command Line arguments",
        "Using checkout on ecommerce for TDD",
        "Test doubles: unitest.mock, monkeypatch",
        "TDD best practices",
      ],
    },
    {
      weekNumber: "Module 5",
      weekTitle: "Microservice Architecture: Practical Implementation",
      content: [
        "Self-contained systems",
        "Frontend integration",
        "Links and  client side integration",
        "Server-side Integration using Edge Side Includes (ESI)",
        "Asynchronous Microservices",
        "Messaging and Kafka",
        "Asynchronous Communication with Atom and REST",
        "Synchronous Microservices",
        "REST with the Netflix Stack",
        " Docker Containers with Kubernetes",
        "PaaS with Cloud Foundry",
      ],
    },
    {
      weekNumber: "Module 6",
      weekTitle: "AWS Architecture",
      content: [
        "The Default Heuristic",
        " Database: DynamoDB",
        "Storage: S3",
        "Compute: EC2",
        "Compute: EC2 Auto Scaling",
        "Compute: Lambda",
        "Networking & Content Delivery: ELB",
        "Networking & Content Delivery: Route 53",
        "Management & Governance: CloudFormation",
        "Application Integration: SQS",
        "Media Service: Kinesis",
        "Starting from Scratch: Basic Web Application",
        "Starting from Scratch: Manual AWS Infrastructure",
        "Infrastructure as Code: Create CloudFormation Stack",
        "Infrastructure as Code: Deploy CloudFormation Stack",
        "Automatic Deployments: CodeBuild",
        "Automatic Deployments: Install CodeDeploy Agent on EC2",
        "Automatic Deployments: Create a CodePipeline",
        "Load Balancing: Add a second EC2 Instance",
        "Load Balancing: Add an Application Load Balancer",
        "Scaling: Add an Auto Scaling Group",
        "Scaling: Remove Instances",
        "Production: Add Stack Name to our Application",
        "Production: Create Staging Stack",
        "Production: Create Production Stack",
        "Custom Domains: Register Domain with Route 53",
        "Custom Domains: Map our Domain to Load Balancers",
        "HTTPS: Create a TLS Certificate",
        "HTTPS: Add an HTTPS Endpoint",
        "HTTPS: Make the Application Speak HTTPS",
        " Network Security: Set up SSM for SSH Access",
        "Network Security: Add Private Subnets with NAT Gateway",
        "Network Security: Enabling HTTPS port in Public Subnets",
      ],
    },
    {
      weekNumber: "Module 7",
      weekTitle: "Preparing for Software Architecture Interviews",
      content: [
        "Preparing for a systems design interview",

        "Using principles from dropbox and netflix, students will be empowered to prepare for systems interview ",
      ],
    },
  ],
  benefits: [
    "Chance to learn on a 30-week interactive Tech programme designed for individuals.",
    "An intimate supportive community of people.",
    "Virtual bootcamp; work while you learn.",
    "Access to mentorship.",
    "Opportunity to learn from Industry experts.",
  ],

  postBenefits: [
    "Reference Letter from the Management of Programmes at Niyo Bootcamps",
    "Completion of a projects to put on your profile (proof of skillset)",
    "Linkedin Badge of completion for you professional profile",
    "Fast-track with any Employer partners of Niyo you apply to work with."
  ],
  eventsText:
    "Our EmpowHerment Evenings aim to inspire, support, educate, empower you to develop the soft skills needed to excel as entrepreneurs. It features sessions on managing your time effectively as an entrepreneur, building self leadership and awareness, ensuring your legals are water-tight and so on.",
  eventsList: [],
  requirementsText:
    "Please note this opportunity is open to candidates based in England only. However, the expectation is that you are either a West Midlands resident or willing to register your business within the West Midlands. You will be expected to show proof of this if selected.",
  requirementsList: [
    "based in the UK.",
    "Aged 19 or over.",
    "You can commit fully to the programme (minimum 6 hours a week).",
    "Already working in a Tech role or have attended a junior level Tech bootcamp.",
    "Possesses working knowledge of either Python, JavaScript or other programming language.",
  ],
  tutorsParagraph:
    "We have experienced tutors from the Tech industry. We also partner with Industry leaders such as Microsoft, KPMG etc. Experienced personnel from these partners teach our students on the bootcamp. This opens more opportunities for students to network with and learn from highly experienced Tech professionals.",
  tutors: [],
};

const IndividualData = { bootcampVideo };
const DataInfo = {
  courseTitle: "Data Analytics & Project Management",
  schedule: "9 Hours / Week | 16 Weeks ",
  applicationLink: "https://zi7e33fld49.typeform.com/niyobootcamps",
  courseRating: rating,
  headingDescription:
    "To upskill and reskill individuals seeking to transition into tech or data careers with the required Data, Project Management, Soft and other transferring skills to help them land tech and data roles and thrive in these roles.",
  heroImage: dataBackground,
  cardTitle: "TECHNICAL BOOTCAMP",
  overviewHeading:
    "Start your career in Data Analytics with Project Management Bootcamp.",
  overviewText:
    "The Data Analytics with Project Management Bootcamp is one of Niyo Bootcamps (a member of Niyo Group) offerings. It is a Skills Bootcamp fully funded by the West Midlands Combined Authority (WMCA) in collaboration with the Department for Education (DfE) to upskill and reskill individuals  seeking to transition into tech or data careers with the required Data, Project Management, Soft and other transferring skills to help them land tech and data roles and thrive in these roles. Learners on this Bootcamp are fondly referred to as DisruptHers because of Niyo's mission to take them from zero to a tech pedestal of becoming a disruptive, high-impact ecosystem.",
  overviewVideo: "https://www.youtube.com/embed/vlIlFWEwjrY?si=SGikFzcz8SAmWVy4",
  syllabusText:
    "This bootcamp helps to upskill and reskill individuals seeking to transition into tech or data careers with the required Data, Project Management, soft and other transferring skills to help them land tech and data roles and thrive in these roles.",
  syllabusLessons: [
    {
      weekNumber: "Module 1",
      weekTitle: "Basic Stats with Excel ",
      content: [
        "Session 1: Intro to Excel",
        " Session 2: Data Entry",
        "Session 3: Functions & Formula ",
        " Session 4: Power Query ",
        "Session 5: Data Cleansing",
        " Session 6: Data Visualization",
        "Session 7: Pivot Table ",
        "Session 8: VBAs & Macrosxe",
      ],
    },
    {
      weekNumber: "Module 2",
      weekTitle: " Dashboard Development with Tableau & PowerBI ",
      content: [
        "Sessions 1-4: Dashboard Development with Tableau",
        "Sessions 5-8: Dashboard Development with PowerBI",
      ],
    },
    {
      weekNumber: "Module 3",
      weekTitle: "SQL",
      content: [
        "Session 1: Databases, Intro to SQL, MySQL Downloads, Create Tables",
        " Session 2: SELECT Statement, WHERE Clause, AND/OR/NOT/BETWEEN",
        "Session 3: IN/LIKE/ISNULL/ORDERBY/GROUP BY/ LIMIT",
        "Session 4: CASE WHEN",
        "Session 5: Joins",
        "Session 6: Subqueries, Window Functions**",
        "Sessions 7: Final Project Walkthrough & GitHub",
        "Session 8: SQL Practical",
      ],
    },
    {
      weekNumber: "Module 4",
      weekTitle: "Intro to Python",
      content: [
        "Sessions 1-4: Fundamentals & Introduction to Python (Variables, IF Statements, Relational operators, LOOP, Data Structure, Functions etc)",
        "Sessions 5-8: Intro to Python Libraries (NumPy, Panda, Matplotlib, Seaborn etc)",
      ],
    },
  ],

  benefits: [
    "Receive professional-level training taught by Industry professionals",
    "Benefit from a fully funded skills bootcamp valued over £10,000.",
    "Qualify for in-demand job titles: Data Analyst , Research Analyst, Business Intelligence or Business Analyst.",
    "Earn a Level 4 qualification ( equivalent of certificate of higher education) rated by industry experts.",
    "Earn a certificate that shows you will have completed and passed all the elements of the Bootcamps.",
    "Your professional profile will be shared with over 25 employers looking to recruit.",
    "Get adequate support from student engagement officers and on your job search even post-bootcamp.",
    "Benefit from learning in a community of talents and associated benefits.",
  ],

  postBenefits: [
    "Reference Letter from the Management of Programmes at Niyo Bootcamps",
    "Completion of a projects to put on your profile (proof of skillset)",
    "Linkedin Badge of completion for you professional profile",
    "Fast-track with any Employer partners of Niyo you apply to work with."
  ],
  eventsText:
    "This is our lingo for other support activities during the Bootcamp. Our EmpowHerment Evenings aims to inspire, support, educate, empower, and develop soft skills in our learners on what it is like to work and thrive in the technology industry. It features vision '20, confidence and killing it, problem-solving, communication, teamwork, working smart and not 'hard' in any role. Other highlights of our empowHerment evenings include but are not limited to:",
  eventsList: [
    "Counselling and well-being therapist support.",
    "CV workshops.",
    "Interview prep sessions.",
    "Careers and employability coaching.",
    "Mentoring sessions, both corporate and individual, on careers and technically.",
    "Check-in sessions.",
    "Office Hours.",
    "Building your digital footprint.",
  ],
  requirementsText:
    "Please note this opportunity is open to candidates based in England only. However, the expectation is that you are either a West Midlands resident or willing to register your business within the West Midlands. You will be expected to show proof of this if selected",
  requirementsList: [
    "Aged 19 and over.",
    "UK Nationals or have resided in the UK for the last 3 years and are based in the West Midlands.",
    "Entitled to public funds.",
    "Not currently in a tech related role.",
    "Able to attend the full 16 weeks (timelines communicated per Bootcamp) online teaching sessions.",
    "Able to participate in a Physical demo day and Screening interviews with Employers",
    "Able to attend empowHERment evenings and other soft skill development elements of the Bootcamp both online and in-person as may be scheduled.",
    "Available for employment both self-sought and through our employer partners following successful completion of the Bootcamp.",
    "Willing to share successful outcomes for interviews and after getting into a role.",
    "A reliable internet connection is advised."
  ],
  tutors: [],
  tutorsParagraph:
    "We have experienced tutors from the Tech industry. We also partner with Industry leaders such as Microsoft, KPMG etc. Experienced personnel from these partners teach our students on the bootcamp. This opens more opportunities for students to network with and learn from highly experienced Tech professionals.",
};

const IndividualWeb = { bootcampVideo };
const WebDevInfo = {
  courseTitle: "Software Development",
  schedule: "9 Hours / Week | 16 Weeks ",
  applicationLink: "https://zi7e33fld49.typeform.com/niyobootcamps",
  courseRating: rating,
  headingDescription:
    "The Software development course is designed to provide participants with a comprehensive understanding of the fundamentals and practical skills needed to develop softwares",
  heroImage: dataBackground,
  cardTitle: "TECHNICAL BOOTCAMP",
  overviewHeading: "Start your career in Software Development Bootcamp.",
  overviewText:
    "The Software Development Bootcamp is one of Niyo Bootcamps (a member of Niyo Group) offerings. It is a Skills Bootcamp fully funded by the West Midlands Combined Authority (WMCA) in collaboration with the Department for Education (DfE) to upskill and reskill individuals seeking to transition into tech careers with the required languages (HTML, Java, etc), Soft and other transferable skills to help them land tech roles and thrive in these roles. Learners on this Bootcamp are fondly referred to as DisruptHers because of Niyo's mission to take them from zero to a tech pedestal of becoming a disruptive, high-impact ecosystem.    ",
  overviewVideo: "https://www.youtube.com/embed/cCch8Qv08c4?si=VCtBELdcHumDYJcy",
  syllabusText:
    "This bootcamp helps to upskill and reskill individuals seeking to transition into tech careers with the required languages and other transferable skills to help them land tech roles and thrive in these roles.",
  syllabusLessons: [
    {
      weekNumber: "Module 1",
      weekTitle: "Advanced CSS",
      content: [
        "Building layouts with CSS: Box model, positioning, and flexbox",
        "Media queries and mobile-first approach",
        "Intro to UI design: Having design sense",
        " Sass & Scss",
        "Accessibility considerations in frontend development"



      ],
    },
    {
      weekNumber: "Module 2",
      weekTitle: " Introduction to JavaScript ",
      content: [
        "Conditionals and loops",
        "Variables, data types, and operators",
        "Functions and scope",
        "DOM manipulation: Selecting elements, modifying content, and handling events",
        "Introduction to asynchronous programming: Callbacks and Promises"

        ,
      ],
    },
    {
      weekNumber: "Module 3",
      weekTitle: "React",
      content: [
        "Overview of React",
        "Setting up a project with react",
        "Props",
        "Building reusable components and managing state",
        "Routing and navigation ",
        "State management library: Redux",
        "Lifecycle",
        "Intro to Next.js"


      ],
    },
    {
      weekNumber: "Module 4",
      weekTitle: "Software Engineering",
      content: [
        " Software Development Life Cycle",
        "Software Engineering Ethics",
        "Software Design Principles and Best Practices",
        "Object-Oriented Design",
        "Debugging and Testing",
        "Common Security Vulnerabilities",
        "Software Project Management",
        "Agile and Scrum Methodologies",
        "Risk Management"
        ,
      ],
    },
    {
      weekNumber: "Module 5",
      weekTitle: "Logic",
      content: [
        "Intro to Data Structures and Algorithms",
        "Introduction to web performance: Page load time and rendering",
        "Optimising asset delivery: Minification, compression, and caching",
        "Performance best practices: Lazy loading, code splitting, and bundle optimization",
        "Performance auditing and testing tools:, WebPageTest, and DevTools",
        "Strategies for optimizing frontend code and improving user experience",
      ],
    },
    {
      weekNumber: "Module 6",
      weekTitle: "Backend Development",
      content: [
        "Understanding the role of backend development in web applications.",
        "Exploring the architecture of a typical web application and the role of the backend.",
        "Installing and configuring the necessary tools and software",
        "Introduction to programming concepts and principles        ",
        "Data types, variables, and operators",
        "Control flow and conditional statements",
        "Functions and modular code organisation",
      ],
    },
    {
      weekNumber: "Module 7",
      weekTitle: "Working with Databases",
      content: [
        "Introduction to databases and their role in backend development",
        "Relational databases (e.g., MySQL, PostgreSQL) vs. NoSQL databases (e.g., MongoDB)",
        "Querying databases using SQL or NoSQL query languages",
        "Data modelling and database design best practices       ",
      ],
    },
    {
      weekNumber: "Module 8",
      weekTitle: "Server-Side Programming Languages and Frameworks",
      content: [
        "Overview of popular server-side programming languages (e.g., Python, Ruby, Node.js, Java)",
        "Exploring backend frameworks (e.g., Django, Ruby on Rails, Express.js, Spring)",
        "Hands-on exercises and projects using a selected programming language and framework",
      ],
    },
    {
      weekNumber: "Module 9",
      weekTitle: " Building RESTful APIs",
      content: [
        "Understanding the principles of REST (Representational State Transfer)",
        "Designing and developing RESTful APIs using industry best practices",
        "Handling HTTP methods (GET, POST, PUT, DELETE) and status codes",
        "Authentication and authorization mechanisms for APIs",
      ],
    },
    {
      weekNumber: "Module 10",
      weekTitle: " Working with Web Servers and Deployment      ",
      content: [
        "Configuring web servers (e.g., Nginx, Apache) for hosting backend applications",
        "Deploying backend applications to production servers or cloud platforms (e.g., AWS, Heroku)",
        "Introduction to containerization (e.g., Docker) and deployment strategies",
        "Overview of testing methodologies for backend applications (e.g., unit testing, integration testing)",
        "Writing and executing test cases using testing frameworks",
        "Debugging techniques and tools for identifying and resolving backend issues",
      ],
    },
    {
      weekNumber: "Module 11",
      weekTitle: " Performance Optimization and Scalability",
      content: [
        " Analysing and improving the performance of backend applications",
        "Caching mechanisms and database optimization techniques",
        "Handling high traffic and scaling backend infrastructure        ",
        "Understanding common security threats and vulnerabilities in backend development",
        "Implementing security measures (e.g., input validation, encryption, access control)",
        "Securing APIs and protecting sensitive data",
        "Exploring advanced backend development concepts (e.g., microservices, serverless architecture)",
        "Introduction to emerging technologies and trends in backend development",
        "Continuous integration and deployment (CI/CD) pipelines",
      ],
    },
    {
      weekNumber: "Module 12",
      weekTitle: " Finale: Project Work",
      content: [
        " Collaborative project development to apply the knowledge and skills acquired throughout the course",
        "Hands-on experience in building a backend application from scratch, incorporating best practices and industry standards and work with frontend technologies.",
      ],
    },
  ],

  benefits: [
    "Receive professional-level training taught by Industry professionals",
    "Benefit from a fully funded skills bootcamp valued over £10,000.",
    "Qualify for in-demand job titles: Fullstack Developer , Software Engineer, Dev-ops and Front-End Developer",
    "Earn a Level 4 qualification ( equivalent of certificate of higher education) rated by industry experts.",
    "Earn a certificate that shows you will have completed and passed all the elements of the Bootcamps.",
    "Your professional profile will be shared with over 25 employers looking to recruit.",
    "Get adequate support from student engagement officers and on your job search even post-bootcamp.",
    "Benefit from learning in a community of talents and associated benefits.",
  ],

  postBenefits : [
    "Reference Letter from the Management of Programmes at Niyo Bootcamps",
    "Completion of a projects to put on your profile (proof of skillset)",
    "Linkedin Badge of completion for you professional profile",
    "Fast-track with any Employer partners of Niyo you apply to work with."
  ],
  eventsText:
    "Our EmpowHerment Evenings aims to inspire, support, educate, empower you to develop the soft skills needed to excel as entrepreneurs. It features sessions on managing your time effectively as an entrepreneur, building self leadership and awareness, ensuring your legals are water tight and so on.",
  eventsList: [
    "Counselling and well-being therapist support.",
    "CV workshops.",
    "Interview prep sessions.",
    "Careers and employability coaching.",
    "Mentoring sessions, both corporate and individual, on careers and technically.",
    "Check-in sessions.",
    "Office Hours.",
    "Building your digital footprint.",
  ],
  requirementsText:
    "Please note this opportunity is open to candidates based in England only. However, the expectation is that you are either a West Midlands resident or willing to register your business within the West Midlands. You will be expected to show proof of this if selected    ",
  requirementsList: [
    "Aged 19 and over.",
    "UK Nationals or have resided in the UK for the last 3 years and are based in the West Midlands.",
    "Entitled to public funds.",
    "Not currently in a tech related role.",
    "Able to attend the full 16 weeks (timelines communicated per Bootcamp) online teaching sessions.",
    "Able to participate in a Physical demo day and Screening interviews with Employers",
    "Able to attend empowHERment evenings and other soft skill development elements of the Bootcamp both online and in-person as may be scheduled.",
    "Available for employment both self-sought and through our employer partners following successful completion of the Bootcamp.",
    "Willing to share successful outcomes for interviews and after getting into a role.",
    "A reliable internet connection is advised."
    ,
  ],
  tutors: [],
  tutorsParagraph:
    "We have experienced tutors from the Tech industry. We also partner with Industry leaders such as Microsoft, KPMG etc. Experienced personnel from these partners teach our students on the bootcamp. This opens more opportunities for students to network with and learn from highly experienced Tech professionals.",
};

const IndividualEntrepreneur = { bootcampVideo };
const EntrepreneurInfo = {
  courseTitle: "Big Break Bootcamp",
  schedule: "12 Hours / Week | 12 Weeks ",
  applicationLink: "https://zi7e33fld49.typeform.com/niyobootcamps",
  courseRating: rating,
  headingDescription:
    "Learn how to optimise your side hustle for your next BIG break. This bootcamp is aimed at those with business ideas, existing businesses, or those looking to embark on a business or freelance career, providing them with the crucial skills required to flourish in today's competitive marketplace; generally self employed individuals.",
  heroImage: dataBackground,
  cardTitle: "Foundher BOOTCAMP",
  overviewHeading: "Learn how to optimise your side hustle for your next BIG break.",
  overviewText:
    "The NIYO Bootcamp, delivered by NIYO GROUP with the support of the West Midlands Combined Authority (WCMA), is a bespoke programme designed to empower Black and Asian women in the West Midlands. This initiative is aimed at those with business ideas, existing businesses, or those looking to embark on a business or freelance career, providing them with the crucial skills required to flourish in today's competitive marketplace; generally self employed individuals. By blending virtual learning with in-person networking events and company visits in Birmingham, the bootcamp offers a unique combination of practical learning, mentorship, and real-world exposure.",
  overviewVideo: "https://www.youtube.com/embed/vlIlFWEwjrY?si=SGikFzcz8SAmWVy4",
  syllabusText:
    "Our detailed syllabus focuses on the core fundamentals of entrepreneurship, tailored to the needs of contemporary entrepreneurs and business owners. Key topics include:",
  syllabusLessons: [
    {
      weekNumber: "Module 1",
      weekTitle: "Entrepreneurial Mindset",
      content: [
        "Developing the proper attitude and approach for business success."



      ],
    },
    {
      weekNumber: "Module 2",
      weekTitle: " Understanding Your Business USP ",
      content: [
        " Identifying and capitalising on your unique selling proposition."

        ,
      ],
    },
    {
      weekNumber: "Module 3",
      weekTitle: "Sales Funnels & Marketing Fundamentals:",
      content: [
        "Constructing and optimising conversion pathways.",
      ],
    },
    {
      weekNumber: "Module 4",
      weekTitle: "Growth Hacking",
      content: [
        " Innovative strategies for accelerated business growth.",
      
      ],
    },
    {
      weekNumber: "Module 5",
      weekTitle: "Financial Management:",
      content: [
        "Understanding the essentials of budgeting, costing, and calculating Lifetime Value (LVC) and Customer Acquisition Cost (CAC).",
      ],
    },
    {
      weekNumber: "Module 6",
      weekTitle: "Branding & Positioning",
      content: [
        "Creating a strong brand identity and market position.",
      ],
    },
    {
      weekNumber: "Module 7",
      weekTitle: "Digital Marketing & Social Media:",
      content: [
        "Using digital platforms to increase business visibility and engagement."
      ],
    },
    {
      weekNumber: "Module 8",
      weekTitle: "Leveraging AI & Technology:",
      content: [
        "Applying technology to optimise or automate business operations.",
      ],
    },
    {
      weekNumber: "Module 9",
      weekTitle: " Traditional Sales Techniques:",
      content: [
        "Combining classic sales strategies with modern methods.",
      ],
    },
  ],

  benefits: [
    "Expert Knowledge: Gain insights from industry leaders and subject matter experts through workshops and interactive sessions. ",
    "Practical Experience: Take part in real-world tasks and challenges to directly apply learning to your business or idea.",
    "Networking Opportunities: Meet like-minded entrepreneurs and professionals at in-person networking events and company visits in Birmingham.",
    "Personalised Mentorship: Benefit from guidance and feedback from experienced mentors tailored to your business needs.",
  ],

  postBenefits : [
    "Continued Support: Gain access to an alumni network and ongoing mentorship opportunities.",
    "Visibility: Opportunities to present your business to potential investors and partners.",
    "Community: Become part of a supportive community of Black and Asian women entrepreneurs in the West Midlands.",
  ],
  eventsText:
    "Our EmpowHerment Evenings aims to inspire, support, educate, empower you to develop the soft skills needed to excel as entrepreneurs. It features sessions on managing your time effectively as an entrepreneur, building self leadership and awareness, ensuring your legals are water tight and so on.",
  eventsList: [
    "Counselling and well-being therapist support.",
    "CV workshops.",
    "Interview prep sessions.",
    "Careers and employability coaching.",
    "Mentoring sessions, both corporate and individual, on careers and technically.",
    "Check-in sessions.",
    "Office Hours.",
    "Building your digital footprint.",
  ],
  requirementsText:
    "To ensure a focused and impactful learning experience, the bootcamp is open to individuals who meet the following criteria: ",
  requirementsList: [
    "Aged 18 and over.",
    "Identify as Black or Asian women",
    "Entitled to public funds.",
    "Resident in the West Midlands",
    "Able to attend the 10 - 12 weeks (timelines communicated per Bootcamp) online teaching sessions.",
    "Self Employed or interested in becoming self employed",
    "Possess a business idea, own a business, have an MVP or are looking to start a business or freelance career",
    "A reliable internet connection is advised."
    ,
  ],
  tutors: [],
  tutorsParagraph:
    "We have experienced tutors from the Tech industry. We also partner with Industry leaders such as Microsoft, KPMG etc. Experienced personnel from these partners teach our students on the bootcamp. This opens more opportunities for students to network with and learn from highly experienced Tech professionals.",
};


const IndividualHair = { bootcampVideo };
const HairInfo = {
  courseTitle: "Resident Afro Hair  Stylist Trainee",
  schedule: "5 Hours / Week | 12 Weeks",
  applicationLink: "https://zi7e33fld49.typeform.com/niyobootcamps",
  courseRating: rating,
  headingDescription:
    "Do you want to take your Hair business to the next level? Learn the business techniques and styling skills that will make you thrive in the industry as a Hair Entrepreneur.",
  heroImage: hairBackground,
  cardTitle: "HAIR & BEAUTY TECH BOOTCAMP",
  overviewHeading:
    "Advance your career in Afro Hair with Digital Entrepreneurship",
  overviewText:
    "The Afro Hair Bootcamp focuses on upskilling students from all backgrounds — including, without limitation, and other ethnic minority groups in hairdressing skills particularly within the Afro hair category. It will be a 12 week course, focusing on different areas within the Afro hair industry, practical and virtual teaching will be the methods implemented.",
  overviewVideo: "https://www.youtube.com/embed/vlIlFWEwjrY?si=SGikFzcz8SAmWVy4",
  syllabusText:
    "At Afro Hair Bootcamp, we want to empower more  Hairdressers to become certified and informed with business skills and training in order to make a difference on their own.",
  syllabusLessons: [
    {
      weekNumber: "Module 1",
      weekTitle: " The Hair Boss’s Dream (Ideation)",
      content: [],
    },
    {
      weekNumber: "Module 2",
      weekTitle: "Understanding your Big Hair empire dream",
      content: [],
    },
    {
      weekNumber: "Module 3",
      weekTitle: "Who are your clientele? & how to treat them",
      content: [],
    },
    {
      weekNumber: "Module 4",
      weekTitle: "Marketing and advertising your ideal clientele",
      content: [],
    },
    {
      weekNumber: "Module 5",
      weekTitle: "Professionalism & Customer Service",
      content: [],
    },
    {
      weekNumber: "Module 6",
      weekTitle: "Brand yourself as a hair boss & Mastering brand visibility",
      content: [],
    },
    {
      weekNumber: "Module 7",
      weekTitle: "Registering your business",
      content: [],
    },
    {
      weekNumber: "Module 8",
      weekTitle: "Pricing: Create your price menu Understanding your profit margins",
      content: [],
    },
    {
      weekNumber: "Module 9",
      weekTitle: "Using technology to leverage your business - Building digital & no code website solutions",
      content: [],
    },
    {
      weekNumber: "Module 10",
      weekTitle: "Using technology to leverage your business - Digital tools that make you passive income e.g., eBooks",
      content: [],
    },
    {
      weekNumber: "Module 11",
      weekTitle: "Mastering your legal policies, insurance and accounts",
      content: [],
    },
    {
      weekNumber: "Module 12",
      weekTitle: "The admin and operations of your hair business",
      content: [],
    },
    {
      weekNumber: "Module 13",
      weekTitle: "Pitch your business demo evening",
      content: [],
    },

  ],
  benefits: [
    "Learn on a 12 week interactive intensive hybrid programme designed for Creative  Hairdressers that want to be  Hair Boss.",
    "Learn the essentials to create a strong long lasting hair business in the first 12 weeks of the bootcamp.",
    "Then take the next next 12 weeks to learn in practical and virtual classes from Celebrity Stylists like Enitan Hair Coach, EA Hair and more.",
    "Gain professional expertise in  Hairstyling.",
    "Learn about ways to access funding as a small business.",
    "Utilise Niyo styling facilities for personal business services at a reduced cost.",
    "Access Products and styling tools for wholesale prices (cheaper than the high street.",
  ],
  postBenefits: [ ],
  eventsText:
    "Our EmpowHerment Evenings aims to inspire, support, educate, empower you to develop the soft skills needed to excel as entrepreneurs. It features sessions on managing your time effectively as an entrepreneur, building self leadership and awareness, ensuring your legals are water tight and so on.",
  eventsList: [],
  requirementsText:
    "Please note this opportunity is open to candidates based in England only. However, the expectation is that you are either a West Midlands resident or willing to register your business within the West Midlands. You will be expected to show proof of this if selected.",
  requirementsList: [
    "Have proof of developing hair styling skills.",
    "Needs a portfolio or place where images/videos of work is stored.",
    "19+ and based in the Midlands.",
    "This  program is designed to introduce students from all backgrounds — including, without limitation, and other ethnic minority groups.",
  ],
  tutorsParagraph:
    "We have experienced tutors from the Tech industry. We also partner with Industry leaders such as Microsoft, KPMG etc. Experienced personnel from these partners teach our students on the bootcamp. This opens more opportunities for students to network with and learn from highly experienced Tech professionals.",
  tutors: [
    {
      tutorImage: tutorOyin,
      tutorName: "Oyinkansola Adebayo",
      tutorTitle: "Chief Executive Officer",
      tutorDescription: "",
    },
    {
      tutorImage: tutorDaniella,
      tutorName: "Daniella Genas",
      tutorTitle: "Founder of She's The Boss International",
      tutorDescription: "",
    },
  ],
};

const IndividualVR = { bootcampVideo };
const VRInfo = {
  courseTitle: "Immersive VR/AR/XR - Building the Metaverse",
  schedule: "5 hours/week | 16 weeks",
  applicationLink: "https://zi7e33fld49.typeform.com/niyobootcamps",
  headingDescription:
    "This program will bridge the gap by upskilling delegates to develop the confidence, attitude and digital skills to succeed in those roles.",
  heroImage: foundhersBackground,
  cardTitle: "WEB3 BOOTCAMP",
  overviewHeading: "Start your career in AR/VR & 3D Design",
  overviewText:
    "There is a greater demand for 3D, VR and other Immersive technology developers than there is currently a supply. This program will bridge the gap by upskilling delegates to develop the confidence, attitude and digital skills to succeed in those roles. The course is designed to put key focus not only on execution of interactive and blended learning styles for millennials, but to increase the chances of their employment in an industry where there is a significant demand for Immersive Technology skills.",
  overviewVideo: "https://www.youtube.com/embed/vlIlFWEwjrY?si=SGikFzcz8SAmWVy4",
  syllabusText:
    "Become familiar with the course programme & set-up of software (Set task of Individual Project Plans).",
  syllabusLessons: [
    {
      weekNumber: "Module 1",
      weekTitle: "Fundamentals of a Real-Time Games Engine.",
      content: [],
    },
    {
      weekNumber: "Module 2",
      weekTitle:
        "Understanding VR headsets work & how to set-up a development of an environment.",
      content: [],
    },
    {
      weekNumber: "Module 3",
      weekTitle: "Materials & Lighting",
      content: [
        "Creation of basic materials for simulating real-world objects like metal and wood",
        "Creation of basic materials for simulating real-world objects like metal and wood",
      ],
    },
    {
      weekNumber: "Module 4",
      weekTitle: "Audio & Effects ",
      content: [
        "Learn how to add audio & sound effects and using scripting to control audio output.",
      ],
    },
    {
      weekNumber: "Module 5",
      weekTitle: "Scripting with Coding",
      content: [
        "Learn the basics of scripting & coding real-time interactions.",
      ],
    },
    {
      weekNumber: "Module 6",
      weekTitle: "User Interfaces (UI)",
      content: ["Understand how to create responsive user interfaces."],
    },
    {
      weekNumber: "Module 7",
      weekTitle: "Cameras & Animation",
      content: ["Learn how to produce animations & cinematic sequences"],
    },
    {
      weekNumber: "Module 8",
      weekTitle: "Publishing Content",
      content: ["Understand how to export content to various platforms."],
    },
    {
      weekNumber: "Module 9",
      weekTitle: "Fundamentals of Blender 3D design software",
      content: ["Learn how to create 3D assets."],
    },
    {
      weekNumber: "Module 10",
      weekTitle:
        "Learn how to import content from Blender or external sources into Unity projects.",
      content: [],
    },
    {
      weekNumber: "Module 11",
      weekTitle: "Individual Project Plan Proposal",
      content: ["Guidance on writing a project plan."],
    },
    {
      weekNumber: "Module 12",
      weekTitle: "Execution of Project",
      content: ["Preparing & creating project outcomes."],
    },
    {
      weekNumber: "Module 13",
      weekTitle: "Finish & Presentations/ Feedback Surveys.",
      content: ["Identifying & clarifying success."],
    },
  ],

  benefits: [
    "Become a certified Unity Designer and Developer",
    "Access to lend equipment needed on the course",
    "A thriving community of peoplw who are passionate about VR and AR",
    "Hands on Employability support",
  ],

  postBenefits: [
   
  ],
  eventsText: "",
  requirementsText: "",
  requirementsList: [],
  tutorsParagraph: "",
  tutors: [
    // {
    //   tutorImage: "",
    //   tutorName: "",
    //   tutorTitle: "",
    //   tutorDescription: "",
    // },
  ],
};

const IndividualFashion = { bootcampVideo, week1, syllabusIcon };
const FashionInfo = {
  courseTitle: "",
  schedule: "",
  courseRating: rating,
  headingDescription: "",
  heroImage: foundhersBackground,
  cardTitle: "",
  overviewHeading: "",
  overviewText: "",
  overviewVideo: "https://www.youtube.com/embed/vlIlFWEwjrY?si=SGikFzcz8SAmWVy4",
  syllabusText: "",
  syllabusLessons: [
    {
      weekNumber: "Week 1",
      weekTitle: "",
      content: "xxxxxxxxxxxx xxxx xxxx xx xxxxxxx  xxxx x xxxx x",
    },
    {
      weekNumber: "Week 2",
      weekTitle: "",
      content: "xxxxxxxxxxxx xxxx xxxx xx xxxxxxx  xxxx x xxxx x",
    },
    {
      weekNumber: "Week 3",
      weekTitle: "",
      content: "xxxxxxxxxxxx xxxx xxxx xx xxxxxxx  xxxx x xxxx x",
    },
    {
      weekNumber: "Weeks 4 & 5",
      weekTitle: "",
      content: "xxxxxxxxxxxx xxxx xxxx xx xxxxxxx  xxxx x xxxx x",
    },
    {
      weekNumber: "Week 6",
      weekTitle: "",
      content: "xxxxxxxxxxxx xxxx xxxx xx xxxxxxx  xxxx x xxxx x",
    },
    {
      weekNumber: "Week 7",
      weekTitle: "",
      content: "xxxxxxxxxxxx xxxx xxxx xx xxxxxxx  xxxx x xxxx x",
    },
    {
      weekNumber: "Weeks 8 & 9",
      weekTitle: "",
      content: "xxxxxxxxxxxx xxxx xxxx xx xxxxxxx  xxxx x xxxx x",
    },
    {
      weekNumber: "Weeks 10 & 11",
      weekTitle: "",
      content: "xxxxxxxxxxxx xxxx xxxx xx xxxxxxx  xxxx x xxxx x",
    },
    {
      weekNumber: "Weeks 12 & 13",
      weekTitle: "",
      content: "xxxxxxxxxxxx xxxx xxxx xx xxxxxxx  xxxx x xxxx x",
    },
    {
      weekNumber: "Weeks 14",
      weekTitle: "",
      content: "xxxxxxxxxxxx xxxx xxxx xx xxxxxxx  xxxx x xxxx x",
    },
    {
      weekNumber: "Week 15",
      weekTitle: "",
      content: "xxxxxxxxxxxx xxxx xxxx xx xxxxxxx  xxxx x xxxx x",
    },
    {
      weekNumber: "Week 16",
      weekTitle: "",
      content: "xxxxxxxxxxxx xxxx xxxx xx xxxxxxx  xxxx x xxxx x",
    },
    {
      weekNumber: "Week 16",
      weekTitle: "",
      content: "xxxxxxxxxxxx xxxx xxxx xx xxxxxxx  xxxx x xxxx x",
    },
  ],
  benefits: ["", "", "", "", "", ""],
  postBenefits: [ ],
  eventsText: "",
  requirementsText: "",
  requirementsList: ["", "", "", "", "", ""],
  tutorsParagraph: "",
  tutors: [
    {
      tutorImage: tutorOyin,
      tutorName: "",
      tutorTitle: "",
      tutorDescription: "",
    },
  ],
};

const IndividualSoftwareTesting = { bootcampVideo, week1, syllabusIcon };
const SoftwareTestingInfo = {
  courseTitle: "Software Testing Bootcamp",
  schedule: "6 hours/week | 12 weeks",
  applicationLink: "https://zi7e33fld49.typeform.com/niyobootcamps",
  headingDescription:
    "This Bootcamp will take you through the Software development cycle and teach you the technical skills need to test software.",
  heroImage: softwareBackground,
  cardTitle: "TECHNICAL BOOTCAMP",
  overviewHeading: "Start your career in Software Testing",
  overviewText:
    "This Bootcamp will take you through the Software development cycle and teach you the technical skills need to test software. After you have learnt all you need to know you will b trained on how to ace your interview with employability tasks and pass the ISTQB exam which certifies you as industry ready!",
  overviewVideo: "https://www.youtube.com/embed/vlIlFWEwjrY?si=SGikFzcz8SAmWVy4",
  syllabusText:
    "The software testing course provides a comprehensive understanding of the principles, techniques, and tools used in software testing. The course covers topics such as testing methodologies, test planning, test execution, and test reporting. Participants will learn how to identify and classify defects, write effective test cases, and execute various testing techniques such as black-box, white-box, and gray-box testing. The course also covers automation testing and its benefits, as well as the tools and frameworks used in automation testing. Upon completion of the course, participants will have the knowledge and skills to create effective test plans, execute tests, and identify and report defects in a software project.",
  syllabusLessons: [
    {
      weekNumber: "Module 1",
      weekTitle: "Software Development Lifecycle ",
      content: [
        "Software development lifecycle: An introduction to what software is, the history of software development and its relevance for today. We will cover at a high level an overview of how software is developed from conception to production.",
        "SDLC methodologies:  Understanding the various SDLC methodologies and how to identify the correct SDLC methodology for products in production. ",
      ],
    },
    {
      weekNumber: "Module 2",
      weekTitle: "Fundamentals of software testing.",
      content: [
        "Software testing:  Answering questions like ‘What is testing?’, ‘Why is it necessary?’, and then focusing on what we testing principles are. Then we will understand test levels, test activities and testing best practices and where the software testing lifecycle fits into the test process. ",
        "Writing Test Cases:  How to structure test plans and formulate test cases. How to approach testing: building correct testing frameworks and learning how to prioritise test scripts by identifying high/low-risk test cases. ",
      ],
    },
    {
      weekNumber: "Module 3",
      weekTitle: "The Psychology of Testing",
      content: [
        "Shifting left: Testers Psychology: Understand what differentiates the testing mindset compared to others and identify what testing skills to develop. This lesson will go over some of the challenges faced by software testers while working in a team and how to navigate them.",
        " Defect Lifecycle: This lesson will focus on identifying software malfunctions, reporting bugs and tracking fixtures  through the defect lifecycle.",
      ],
    },
    {
      weekNumber: "Module 4",
      weekTitle: "Test Types & Techniques",
      content: [
        "Session 1: This lesson looks at what test types are, static vs dynamic testing and test techniques (white box, box and experience-based testing).",
        "Session 2: How do we apply test techniques that we’ve learnt about and how can you identify when to use them in your future roles?",
      ],
    },
    {
      weekNumber: "Module 5",
      weekTitle: "Web and Mobile Testing",
      content: [
        "Cross-browser testing: Covering the importance of cross browser testing and what this looks like when carrying out test cases.",
        "Mobile Testing: Focusing on the differences between in-app testing and browser mobile testing. Highlighting the things, we have to consider and document when carrying out mobile testing.",
      ],
    },
    {
      weekNumber: "Module 6",
      weekTitle: "Backend testing",
      content: [
        "Postman – practical:  This lesson will look at how we can use API testing to do backend testing while understanding how this tool fits in with other testing tools.",
        "SQL – practical: This week will focus on how the database works hand in hand with software and how we can test this (and document testing).",
      ],
    },
    {
      weekNumber: "Module 7",
      weekTitle: "Test Tools",
      content: [
        "Session 1: Test case management tools: Jira, qTest/Test Rail, Confluence, etc.",
        "Session 2: Tools and environments. Some of the tools that may be incorporated during class will be GitHub, Jenkins, Docker, Visual Studio, Fiddler, Swagger, etc. ",
        "Session 3: Writing test cases with test tools in mind – practical.",
        "Session 4: Executing test cases with test tools and logging results correctly – practical.",
      ],
    },
    {
      weekNumber: "Module 8",
      weekTitle: "Testing Practical Week",
      content: [
        "Session 1: Putting everything in practise: doing a project together from start to finish. ",
        "Session 2: Picking up a project, putting together test scripts and executing and logging results accordingly.",
        "Session 3: Test completion.",
      ],
    },
    {
      weekNumber: "Module 9",
      weekTitle: "Writing CVs and Cover letters",
      content: [
        "Cover where to find independent work to put build work portfolio for CVs and cover letter. Applying for jobs and how to stand out from the crowd.",
      ],
    },
    {
      weekNumber: "Module 10",
      weekTitle: "Interview Questions",
      content: [
        "Session 1: There are both theoretical questions to prepare for in interviews and skills to manoeuvre them. Learn how to identify practical questions to prepare for in interviews for presentations.",
        "Session 2: Finally, we will then go over as many interview questions in a mock interview set-up and go through pop quizzes for ISTQB revision materials to prepare students for exam.",
      ],
    },
  ],
  benefits: [
    "The chance to learn on an intensive 12-week Bootcamp to jumpstart your career in Software Testing. ",
    "An Intimate supportive community of techies.",
    "The ability to implement backend testing and develop software.",
    "Opportunity to learn from Industry experts and Entrepreneurs with years of Experience.",
    "Access to mentorship and career coaching provided by our Career and Employability Coach.",
    "Ability to develop your practical and implementation skills.",
    "Advanced Technical Skills to help branch out further into your career.",
    "Post Graduation Support.",
  ],

  postBenefits: [
    "Reference Letter from the Management of Programmes at Niyo Bootcamps",
    "Completion of a projects to put on your profile (proof of skillset)",
    "Linkedin Badge of completion for you professional profile",
    "Fast-track with any Employer partners of Niyo you apply to work with."
  ],
  eventsText:
    "Our EmpowHerment Evenings aims to inspire, support, educate, empower you to develop the soft skills needed to excel as entrepreneurs. It features sessions on managing your time effectively as an entrepreneur, building self leadership and awareness, ensuring your legals are water tight and so on.",
  requirementsText:
    "Please note this opportunity is open to candidates based in England only. However, the expectation is that you are either a West Midlands resident or willing to register your business within the West Midlands. You will be expected to show proof of this if selected",
  requirementsList: [
    "Individuals based in England",
    "Aged 19 or over.",
    "You can commit fully to the programme (6hours a week).",
    "Businesses developing creative Tech solution or looking for how to use Tech to scale (in delivery, quality assurance or operations).",
    "Must have an interest in Software development and Testing.",
    "Previous knowledge and experience isn’t compulsory but is desired.",
  ],
  tutorsParagraph:
    "We have experienced tutors from the Tech industry. We also partner with Industry leaders such as Microsoft, KPMG etc. Experienced personnel from these partners teach our students on the bootcamp. This opens more opportunities for students to network with and learn from highly experienced tech professionals.",
  tutors: [
    // {
    //   tutorImage: tutorOyin,
    //   tutorName: "",
    //   tutorTitle: "",
    //   tutorDescription: "",
    // },
  ],
};

///////// JOBS

const JobsImages = {
  jobsHero,
  jobsLogo,
  progressHalf,
  progressFull,
  wallet,
  stripe,
  goCardless,
};

const JobRoles = [
  {
    title: "Javascript Developer",
    company: "Niyo Group",
    contract: "Freelance",
    location: "Virtual",
    link: "https://www.niyo.co/",
  },
  {
    title: "Partnerships Executive",
    company: "BelleVie Care",
    contract: "Full-time",
    location: "Birmingham UK",
    link: "https://www.niyo.co/",
  },
  {
    title: "Javascript Developer",
    company: "Niyo Group",
    contract: "Freelance",
    location: "Virtual",
    link: "https://www.niyo.co/",
  },
  {
    title: "Back End Developer",
    company: "BelleVie Care",
    contract: "Full-time",
    location: "Birmingham UK",
    link: "https://www.niyo.co/",
  },
  {
    title: "Product Designer",
    company: "Niyo Group",
    contract: "Freelance",
    location: "Virtual",
    link: "https://www.niyo.co/",
  },
  {
    title: "UX Designer",
    company: "BelleVie Care",
    contract: "Full-time",
    location: "Birmingham UK",
    link: "https://www.niyo.co/",
  },
  {
    title: "Javascript Developer",
    company: "Niyo Group",
    contract: "Freelance",
    location: "Virtual",
    link: "https://www.niyo.co/",
  },
  {
    title: "Javascript Developer",
    company: "BelleVie Care",
    contract: "Full-time",
    location: "Birmingham UK",
    link: "https://www.niyo.co/",
  },
  {
    title: "Javascript Developer",
    company: "Niyo Group",
    contract: "Freelance",
    location: "Virtual",
    link: "https://www.niyo.co/",
  },
  {
    title: "Javascript Developer",
    company: "BelleVie Care",
    contract: "Full-time",
    location: "Birmingham UK",
    link: "https://www.niyo.co/",
  },
  {
    title: "Javascript Developer",
    company: "Niyo Group",
    contract: "Freelance",
    location: "Virtual",
    link: "https://www.niyo.co/",
  },
  {
    title: "Javascript Developer",
    company: "BelleVie Care",
    contract: "Full-time",
    location: "Birmingham UK",
    link: "https://www.niyo.co/",
  },
];

const JobPackagesPremium = [
  {
    id: "premium-1",
    image: jobTag,
    number: " One",
    list: [
      "1 job post which will last for 30 days on our job board once approved.",
      "This package also includes a Social Media Post.",
      "Featured position at the top of our job board.",
    ],
    price: "149",
  },
  {
    id: "premium-2",
    image: jobTag,
    number: " Two",
    list: [
      "3 job posts which will last for 30 days on our job board once approved.",
      "This package also includes a Social Media Post.",
      "Featured position at the top of our job board.",
    ],
    price: "249",
  },
  {
    id: "premium-3",
    image: jobTag,
    number: " Three",
    list: [
      "5 job posts which will last for 30 days on our job board once approved.",
      "This package also includes Social Media Post.",
      "Featured position at the top of our job board.",
    ],
    price: "399",
  },
];

const JobPackagesBasic = [
  {
    id: "basic-1",
    image: jobTag,
    number: " One",
    list: [
      "1 job post which will last for 30 days on our job board once approved.",
    ],
    price: "149",
  },
  {
    id: "basic-2",
    image: jobTag,
    number: " Two",
    list: [
      "3 job posts which will last for 30 days on our job board once approved.",
    ],
    price: "249",
  },
  {
    id: "basic-3",
    image: jobTag,
    number: "Three",
    list: [
      "5 job posts which will last for 30 days on our job board once approved.",
    ],
    price: "399",
  },
];

const JobFAQs = [
  {
    question: "Can I change plan?",
    answer: "xxxxxxxxxxxxxxxxxx",
  },
  {
    question: "Which payment methods can I use?",
    answer: "xxxxxxxxxxxxxxxxxx",
  },
  {
    question: "Can I post the job myself?",
    answer: "xxxxxxxxxxxxxxxxxx",
  },
  {
    question: "Do you offer discounts for Premium users?",
    answer: "xxxxxxxxxxxxxxxxxx",
  },
];

const JobTypes = [
  "AR/VR",
  "Cyber",
  "Data Analysis",
  "Digital Marketing",
  "Product Management",
  "Project Management",
  "Software Engineer",
  "Software Testing",
  "UX and UI",
  "Website Development",
];

const JobLevels = [
  "Junior",
  "Mid-Level",
  "Senior",
  "Apprenticeship",
  "Graduate Programme",
];

const Locations = ["Remote", "Birmingham, UK", "London"];

export {
  ourSocials,
  Logos,
  Background,
  ChooseJobRole,
  HomeImages,
  PartnerBrands1,
  PartnerBrands2,
  bespokeSupport,
  ourGrads,
  ourBootcamps,
  HoursWorked,
  ourStoryImages,
  upskillEvents,
  impactStats,
  bootcampTeam,
  newsArticles,
  partnersImages,
  partnerCards,
  workBoxes,
  offeringCards,
  jobLevels,
  noEmployees,
  BootcampImages,
  web2BootcampInfo,
  web3BootcampInfo,
  hairBootcampInfo,
  foundherBootcampInfo,
  Web2BootcampsIndividual,
  Web2BootcampsComingSoon,
  Web3BootcampsIndividual,
  Web3BootcampsComingSoon,
  HairBootcampsIndividual,
  HairBootcampsComingSoon,
  FoundhersBootcampsIndividual,
  FashionBootcampsIndividual,
  FashionBootcampsComingSoon,
  BootcampFAQS,
  FormTimelineImages,
  SpecificBenefit,
  CurrentEmploymentStatus,
  ContinueWork,
  SpecificDisability,
  CurrentSalary,
  StudyLevel,
  AgeGroups,
  Ethnicities,
  applyEthnicities,
  Subject,
  applyingPrior,
  employementStatus,
  UploadButtons,
  MediaMethods,
  MyCheckBoxList,
  BootcampWindow,
  TechnologyUse,
  BusinessStage,
  BusinessSector,
  FundingReceived,
  BusinessStructure,
  TeamMembersParticaption,
  TeamMembers,
  NoFoundher,
  RoleTitle,
  CommunityImages,
  CommunityData,
  IndividualFoundhers,
  IndividualSoftware,
  EntrepreneurInfo,
  IndividualEntrepreneur,
  IndividualData,
  IndividualHair,
  IndividualVR,
  WebDevInfo,
  IndividualWeb,
  IndividualFashion,
  IndividualSoftwareTesting,
  FoundhersInfo,
  SoftwareInfo,
  IndividualMarketing,
  Industry,
  DataInfo,
  HairInfo,
  MarketingInfo,
  VRInfo,
  FashionInfo,
  SoftwareTestingInfo,
  JobsImages,
  JobRoles,
  JobPackagesPremium,
  JobPackagesBasic,
  JobFAQs,
  JobTypes,
  JobLevels,
  Locations,
};
